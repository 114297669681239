import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { musicalNote, close, create, headset } from "ionicons/icons";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import ICancion from "../components/Interfaces/ICancion";
import IGrupoCanciones from "../components/Interfaces/IGrupoCanciones";
import CelebracionesContext from "../contextManagment/celebraciones/celebracionesContext";
import parse from "html-react-parser";

const ListCancionesGrupo = () => {
  const { idGrupo } = useParams<{ idGrupo: string }>();
  ///CONTEXT
  // const { userActive } = useContext(UserContext);
  const { grupoCanciones, canciones, getcancionesTodas, getGruposBd } =
    useContext(CelebracionesContext);
  ///STATE INTERNOS
  const [idGrupoActual, setIdGrupoActual] = useState<string>("");
  const [grupo, setGrupo] = useState<IGrupoCanciones | null>(null);
  const [mostrarModalCancion, setMostrarModalCancion] = useState(false);
  const ModalCancion = useRef<HTMLIonModalElement>(null);
  const [letraCancion, setLetraCancion] = useState<string | "">();
  const [tituloCancionSeleccionada, setTituloCancionSeleccionada] = useState<
    string | ""
  >();
  const [idCancionSeleccionada, setIdCancionSeleccionada] =
    useState<string>("");

  useEffect(() => {
    setIdGrupoActual(idGrupo);
  }, [idGrupo]);

  const history = useHistory();

  useEffect(() => {
    if (grupoCanciones?.length === 0) {
      getGruposBd();
    }
    if (canciones?.length === 0) {
      getcancionesTodas();
    }
    const grup = grupoCanciones.find(
      (d: IGrupoCanciones) => d.Id === idGrupoActual
    );
    setGrupo(grup);
  }, [idGrupoActual]);

  useEffect(() => {
    const grup = grupoCanciones.find(
      (d: IGrupoCanciones) => d.Id === idGrupoActual
    );
    setGrupo(grup);
  }, [canciones, grupoCanciones]);

  const mostrarCancionSeleccionada = (cancion: ICancion) => {
    history.push(`/page/CancionEscucha/${cancion.Id}`);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{`Grupo: ${grupo?.NombreGrupo}`}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonHeader collapse="condense">
        <IonToolbar>
          <IonTitle size="large">{`Grupo: ${grupo?.NombreGrupo}`}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent scrollY={true}>
        <IonList>
          {grupo && grupo?.CancionesDelGrupo.length > 0 ? (
            grupo.CancionesDelGrupo.map((c) => (
              <IonItem key={c.Id} onClick={() => mostrarCancionSeleccionada(c)}>
                {c.TieneAudio ? (
                  <IonIcon slot="start" icon={headset} />
                ) : (
                  <IonIcon slot="start" icon={musicalNote} />
                )}
                <IonLabel>
                  <h2 style={{ fontWeight: "500" }}>{c.Nombre}</h2>
                  <p style={{ fontSize: "smaller" }}>{c.Descripcion}</p>
                </IonLabel>
              </IonItem>
            ))
          ) : (
            <IonItem>No existen canciones en este grupo</IonItem>
          )}
        </IonList>
      </IonContent>

      <IonModal
        ref={ModalCancion}
        onWillDismiss={() => setMostrarModalCancion(false)}
        isOpen={mostrarModalCancion}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton
                onClick={() => {
                  ModalCancion.current?.dismiss();
                }}
              >
                <IonIcon icon={close} />
              </IonButton>
            </IonButtons>
            <IonTitle className="ion-text-center">
              {tituloCancionSeleccionada}
            </IonTitle>

            <IonButtons slot="start">
              <IonButton
                onClick={() => {
                  setMostrarModalCancion(false);
                  history.push(`/page/CancionPage/${idCancionSeleccionada}`);
                }}
              >
                <IonIcon icon={create} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <pre
            className="preCancion"
            style={{
              fontSize: `1.2rem`,
              lineHeight: "normal",
              margin: "0.8rem ",
              paddingBottom: "15px",
            }}
          >
            {parse(letraCancion ? letraCancion : "")}
          </pre>
        </IonContent>
      </IonModal>
    </IonPage>
  );
};

export default ListCancionesGrupo;
