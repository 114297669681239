import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonHeader,
  IonIcon,
  IonInput,
  IonLoading,
  IonMenuButton,
  IonModal,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToolbar,
  useIonAlert,
  IonItem,
  IonList,
  IonCard,
  IonLabel,
  IonCardContent,
  IonSearchbar,
  IonText,
} from "@ionic/react";

import {
  close,
  searchCircle,
  closeCircle,
  save,
  chevronDownCircle,
  playCircleOutline,
} from "ionicons/icons";

import { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import IGrupoCanciones from "../components/Interfaces/IGrupoCanciones";
import CelebracionesContext from "../contextManagment/celebraciones/celebracionesContext";
import IAcorde from "../components/Interfaces/IAcorde";
import parse from "html-react-parser";
import ICancion from "../components/Interfaces/ICancion";
import AppTypeahead from "../components/Canciones/SelectAcorde";
import GestorAudios from "../components/Canciones/GestorAudios";
import UserContext from "../contextManagment/user/userContext";

interface Props {
  forceUpdateCancionEscucha: number;
  setForceUpdateCancionEscucha: Function;
}

const CancionPage = ({
  forceUpdateCancionEscucha,
  setForceUpdateCancionEscucha,
}: Props) => {
  const { id } = useParams<{ id: string | undefined }>();
  const [contenidoTxt, setContenidoTxt] = useState<string | null>("");
  const [nombreCancion, setNombreCancion] = useState<string>("");
  const [descripcionCancion, setDescripcionCancion] = useState<string>("");
  const [optionGrupSelected, setOptionGrupSelected] = useState();
  const [optionAcordes, setOptionAcordes] = useState<string>();
  const [tieneAudio, setTieneAudio] = useState(false);
  const [error, setError] = useState<string>("");
  const {
    mostrarLoading,
    acordes,
    grupoCanciones,
    cancionAgregando,
    getGruposBd,
    guardaCancion,
    setCancionAgregando,
    GetAcordes,
    canciones,
    setCancionAEditar,
    idCancionAEditar,
    setReproduciendo,
    getcancionesTodas,
  } = useContext(CelebracionesContext);
  const { profileUser } = useContext(UserContext);

  const history = useHistory();
  const [present] = useIonAlert();
  const ModalCancion = useRef<HTMLIonModalElement>(null);
  const [mostrarModalCancion, setMostrarModalCancion] = useState(false);
  const [previsualizar, setPrevisualizar] = useState<string>("");
  const [mostrarModalGestionarAudio, setMostrarModalGestionarAudio] =
    useState(false);
  //const modalGestionarAudio = useRef<HTMLIonModalElement>(null);
  let loc = useLocation();

  ///-----------------------------------------------
  const [selectedAcordeBaseText, setSelectedAcordeBaseText] =
    useState<string>("");
  const [selectedAcordeBase, setSelectedAcordeBase] = useState<string[]>([]);
  const [results, setResults] = useState([]);
  const modal = useRef<HTMLIonModalElement>(null);

  const acordeBaseSelectionChanged = (acordes: string[]) => {
    setSelectedAcordeBase(acordes);
    setSelectedAcordeBaseText(acordes[0]);
    setOptionAcordes(acordes[0]);
    modal.current?.dismiss();
  };

  function ordenarAcordes(a: IAcorde, b: IAcorde) {
    // Compara por Tipo
    if (a.Tipo !== b.Tipo) {
      return a.Tipo.localeCompare(b.Tipo);
    }
    // Si los Tipos son iguales, compara por Orden
    return a.Orden - b.Orden;
  }

  ///------------------------------------

  useEffect(() => {
    if (id === "new") {
      setNombreCancion("");
      setSelectedAcordeBaseText("");
    } else {
      if (id) setCancionAEditar(id);
    }
    setReproduciendo(false);
  }, [loc]);

  useEffect(() => {
    let cancion = canciones.find((c: ICancion) => c.Id === id);
    if (cancion) {
      setNombreCancion(cancion.Nombre);
      setDescripcionCancion(cancion.Descripcion);
      setOptionGrupSelected(cancion.Grupo);
      let letraString = cancion.Cancion.replace(/<(?!\/?b(?:\s|>)).*?>/gi, "");
      setContenidoTxt(letraString);
      setOptionAcordes(cancion.AcordeBase);
      setSelectedAcordeBaseText(cancion.AcordeBase);
      setSelectedAcordeBase([cancion.AcordeBase]);
      setTieneAudio(cancion.TieneAudio);
    }
  }, [idCancionAEditar]);

  useEffect(() => {
    if (grupoCanciones.length === 0) getGruposBd();
    if (canciones.length === 0) getcancionesTodas();
    if (acordes.length === 0) GetAcordes();
    if (id !== idCancionAEditar) setCancionAEditar(id);
    if (id === "new") {
      setOptionGrupSelected(undefined);
      setDescripcionCancion("");
      setNombreCancion("");
      setContenidoTxt("");
      //setOptionAcordes("");
      setTieneAudio(false);
      setSelectedAcordeBaseText("");
      // setSelectedAcordeBase([]);
    }
  }, [grupoCanciones, acordes, id]);

  useEffect(() => {
    if (cancionAgregando === true) {
      setCancionAgregando(false);
      present({
        header: "Exito",
        message: "La canción se agregó correctamente",
        buttons: ["OK"],
        onDidDismiss: () => {
          Redireccionar();
        },
      });
    }
  }, [cancionAgregando]);

  const guardarCancion = () => {
    if (nombreCancion === "") {
      present({
        header: "¡  !",
        message: "Es necesario el nombre de la canción",
        buttons: ["OK"],
        onDidDismiss: () => {
          return;
        },
      });
      return;
    }
    if (descripcionCancion === "") {
      present({
        header: "¡  !",
        message: "Es necesario indicar una descripción",
        buttons: ["OK"],
        onDidDismiss: () => {
          return;
        },
      });
      setError("Es necesario indicar una descripción");
      return;
    }

    if (!optionGrupSelected) {
      present({
        header: "¡  !",
        message: "Es necesario indicar un grupo",
        buttons: ["OK"],
        onDidDismiss: () => {
          return;
        },
      });
      setError("Es necesario indicar un grupo");
      return;
    }

    if (contenidoTxt?.trim() === "") {
      present({
        header: "¡  !",
        message: "Escribe la canción antes de guardar",
        buttons: ["OK"],
        onDidDismiss: () => {
          return;
        },
      });
      setError("Escribe la canción antes de guardar");
      return;
    }

    setError("");

    const song = {
      Id: id,
      Nombre: nombreCancion,
      Descripcion: descripcionCancion,
      Grupo: optionGrupSelected ? optionGrupSelected : "",
      Cancion: contenidoTxt ? pasaAcordesToClass(contenidoTxt) : "",
      Creador: "",
      Organizacion: "",
      RutaMultimedia: [],
      AcordeBase: optionAcordes,
      TieneAudio:
        tieneAudio === null || tieneAudio === undefined ? false : tieneAudio,
    };
    guardaCancion(song);
    setForceUpdateCancionEscucha(forceUpdateCancionEscucha + 1);
  };

  const setLetraCancion = () => {
    let valueTxt = contenidoTxt ? contenidoTxt : "";
    let letra = pasaAcordesToClass(valueTxt);
    setPrevisualizar(letra ? letra : "");
    setMostrarModalCancion(true);
  };

  const previsualizarDesdeBusqueda = async (ca: ICancion) => {
    let letra;
    if (ca.Cancion) {
      letra = pasaAcordesToClass(ca.Cancion);
    }
    setNombreCancion(ca.Nombre ? ca.Nombre : "");
    setPrevisualizar(letra ? letra : "");
    setMostrarModalCancion(true);
  };

  const ordenarAcordesEvaluacion = (a: IAcorde, b: IAcorde) => {
    return a.OrdEval - b.OrdEval;
  };

  const pasaAcordesToClass = (cancionLimpia: string) => {
    if (!cancionLimpia) return;
    let lineas = cancionLimpia.split("\n");
    let arrProcesado: string[] = [];
    lineas.forEach((lin) => {
      acordes.sort(ordenarAcordesEvaluacion).forEach((ac: IAcorde) => {
        let palabras = lin.split(" ");
        let auxLinea: string[];
        auxLinea = palabras.map((p) => {
          let aux = p;
          if (p === ac.Id) {
            aux = `<span class="Acorde">${ac.Id}</span>`;
          } else {
            let variaciones = ac.Variaciones.split("-");
            variaciones.forEach((vari) => {
              if (p === vari) {
                aux = `<span class="Acorde">${ac.Id}</span>`;
              }
            });
          }
          return aux;
        });

        lin = auxLinea.join(" ");
      });
      arrProcesado.push(lin);
    });
    return arrProcesado.join("\n");
  };

  const Redireccionar = async () => {
    let grupo = await grupoCanciones.find(
      (g: any) => g.NombreGrupo === optionGrupSelected
    );
    setOptionGrupSelected(undefined);
    setDescripcionCancion("");
    setNombreCancion("");
    setContenidoTxt("");
    setContenidoTxt("");
    setCancionAEditar("");
    if (grupo) {
      history.push(`/page/ListCancionesGrupo/${grupo.Id}`);
    } else {
      history.push(`/page/BancoCancionesPage`);
    }
  };

  //BUSCAR CANCIÓN
  const buscarCancion = (ev: any) => {
    let query: string = "";
    const target = ev.target as HTMLIonSearchbarElement;
    setNombreCancion(target.value ? target.value : "");
    if (target) {
      query = target
        .value!.toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
    }

    if (query === "") {
      setResults([]);
      return;
    }

    if (canciones) {
      setResults(
        canciones.filter((d: ICancion) => {
          //busqueda en minusculas y sin acentos
          return (
            (d.Nombre
              ? d.Nombre.toLocaleLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .indexOf(query) > -1
              : 0) ||
            (d.Cancion
              ? d.Cancion.toLocaleLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .indexOf(query) > -1
              : 0)
          );
        })
      );
    }
  };

  const abrirGestionDeAudio = () => {
    setMostrarModalGestionarAudio(true);
  };

  return (
    <IonPage>
      <IonLoading
        translucent={true}
        isOpen={mostrarLoading}
        message={"Espera un momento..."}
      />

      <IonFab
        style={{ marginTop: "-0.6rem" }}
        slot="end"
        vertical="top"
        horizontal="end"
        edge={false}
      >
        <IonFabButton>
          <IonIcon icon={chevronDownCircle}></IonIcon>
        </IonFabButton>
        <IonFabList side="bottom">
          <IonFabButton onClick={guardarCancion}>
            <IonIcon icon={save}></IonIcon>
          </IonFabButton>
          {profileUser.Roles.includes("Administrador") ? (
            <IonFabButton onClick={() => abrirGestionDeAudio()}>
              <IonIcon icon={playCircleOutline}></IonIcon>
            </IonFabButton>
          ) : null}

          <IonFabButton onClick={Redireccionar}>
            <IonIcon icon={closeCircle}></IonIcon>
          </IonFabButton>
        </IonFabList>
      </IonFab>

      <IonHeader style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
        <IonToolbar>
          <IonRow>
            <IonCol size="10">
              <IonButtons slot="start">
                <IonMenuButton />
              </IonButtons>
              <IonSearchbar
                className="ion-margin-end"
                placeholder="Indica un nombre"
                searchIcon={searchCircle}
                onIonChange={(e) => {
                  setNombreCancion(`${e.target.value}`);
                }}
                value={nombreCancion}
                onIonInput={(ev) => buscarCancion(ev)}
                onIonBlur={() => {
                  setTimeout(() => {
                    setResults([]);
                  }, 200);
                }}
              ></IonSearchbar>
            </IonCol>
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonRow>
          <IonCol size="12">
            {results.length > 0 ? (
              <div
                style={{
                  border: "1px solid #9CEFF0",
                  //position: "absolute",
                  width: "95%",
                  //height: "0px",
                  zIndex: 5,
                }}
              >
                <IonList style={{ marginTop: "-1rem" }}>
                  {results.map((ca: ICancion, indx) => (
                    <IonItem
                      color="light"
                      key={indx}
                      onClick={() => previsualizarDesdeBusqueda(ca)}
                    >
                      <IonText>{ca.Nombre}</IonText>
                    </IonItem>
                  ))}
                </IonList>
              </div>
            ) : null}

            <IonButton
              onClick={() => setLetraCancion()}
              fill="outline"
              color="tertiary"
              expand="block"
            >
              Previsualizar
            </IonButton>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonSelect
              placeholder="Del Grupo"
              className="bordesSuaves tertiary-color"
              value={optionGrupSelected}
              onIonChange={(e) => {
                setError("");
                setOptionGrupSelected(e.detail.value);
              }}
            >
              {grupoCanciones.map((g: IGrupoCanciones) => (
                <IonSelectOption key={g.Id} value={g.NombreGrupo}>
                  {g.NombreGrupo}
                </IonSelectOption>
              ))}
            </IonSelect>
            <IonList inset={true} style={{ margin: "0px", padding: "0px" }}>
              <IonItem
                button={true}
                detail={false}
                id="select-acordeBase"
                className="ion-text-start bordesSuaves"
                style={{ with: "100%", heigth: "2.5rem !important" }}
              >
                <IonInput
                  color={"tertiary"}
                  style={{ marginLeft: "0.98rem" }}
                  placeholder="Acorde Base"
                  value={selectedAcordeBaseText && selectedAcordeBaseText}
                ></IonInput>
              </IonItem>
            </IonList>
          </IonCol>
          <IonCol>
            <IonTextarea
              className="bordesSuaves"
              autoGrow={true}
              placeholder="Indica una descripción"
              value={descripcionCancion}
              style={{
                minHeight: "10.5vh",
              }}
              onIonChange={(e: any) => {
                setError("");
                setDescripcionCancion(e.target.value);
              }}
            ></IonTextarea>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonTextarea
            style={{
              fontSize: "1.2rem",
            }}
            className="bordesSuaves"
            autoGrow={true}
            placeholder="Ingresa la letra de la canción"
            value={contenidoTxt}
            onIonChange={(e: any) => {
              setError("");
              setContenidoTxt(e.target.value);
            }}
          ></IonTextarea>
        </IonRow>
      </IonContent>

      <IonModal
        ref={ModalCancion}
        onWillDismiss={() => setMostrarModalCancion(false)}
        isOpen={mostrarModalCancion}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>Previsualización</IonTitle>
            <IonButtons slot="end">
              <IonButton
                onClick={() => {
                  ModalCancion.current?.dismiss();
                }}
              >
                <IonIcon slot="end" icon={close} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen={true}>
          <IonCard>
            <IonItem color="light" lines="none">
              <IonLabel style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                {nombreCancion}
              </IonLabel>
            </IonItem>
            <IonCardContent>
              <pre
                style={{
                  fontSize: `1.2rem`,
                  lineHeight: "normal",
                  margin: "0.8rem ",
                  paddingBottom: "15px",
                }}
                className="preCancion"
              >
                {parse(previsualizar ? previsualizar : "")}
              </pre>
            </IonCardContent>
          </IonCard>
        </IonContent>
      </IonModal>
      <IonModal trigger="select-acordeBase" ref={modal} initialBreakpoint={0.6}>
        <AppTypeahead
          items={acordes
            .filter((a: IAcorde) => {
              return a.Tipo === "May" || a.Tipo === "men";
            })
            .sort(ordenarAcordes)}
          selectedItems={selectedAcordeBase}
          onSelectionCancel={() => modal.current?.dismiss()}
          onSelectionChange={acordeBaseSelectionChanged}
        />
      </IonModal>
      <GestorAudios
        idCancion={idCancionAEditar}
        nombreCancion={nombreCancion}
        key={`gestor-${idCancionAEditar}`}
        modalAbierta={mostrarModalGestionarAudio}
        setModalAbierta={setMostrarModalGestionarAudio}
      />
    </IonPage>
  );
};

export default CancionPage;
