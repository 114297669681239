import {
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonInput,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonPage,
  IonContent,
  IonGrid,
  IonText,
  IonCheckbox,
  IonList,
  IonRadioGroup,
  IonRadio,
} from "@ionic/react";

import { personCircle } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import CelebracionesContext from "../contextManagment/celebraciones/celebracionesContext";
import UserContext from "../contextManagment/user/userContext";

export default function CreacionUsuarioPage() {
  const { nuevoUsuarioCreado, registrar, userError } = useContext(UserContext);
  const { comunidadesTodas } = useContext(CelebracionesContext);
  const [Correo, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [errorFormulario, setErrorFormulario] = useState("");
  const [Organizaciones, setOrganizaciones] = useState<string[]>([]);
  const [comunidadPredeterminada, setComunidadPredeterminada] =
    useState<string>("");
  const [Nombre, setNombre] = useState("");
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  useEffect(() => {
    setErrorFormulario("");
  }, [nuevoUsuarioCreado]);

  const handleLogin = async (e: any) => {
    if (!(Correo || Password || Nombre || Organizaciones))
      setErrorFormulario("Todos los campos son necesarios");

    if (Password.length < 6) {
      setErrorFormulario("La contraseña debe tener almenos 6 digitos");
      return;
    }
    if (!Organizaciones.length) {
      setErrorFormulario("Selecciona una comunidad");
      return;
    }
    if (!comunidadPredeterminada) {
      setErrorFormulario(
        "Selecciona una comunidad predeterminada desde el radiobutton"
      );
      return;
    }
    if (!selectedRoles.length) {
      setErrorFormulario("Selecciona una rol");
      return;
    }
    const modelLogin = {
      Correo,
      Password,
      Nombre,
      Organizacion: comunidadPredeterminada,
      Comunidades: Organizaciones,
      Roles: selectedRoles,
    };

    registrar(modelLogin);
  };

  const handleRadioChange = (e: any) => {
    if (e.srcElement.role === "radiogroup") {
      setComunidadPredeterminada(e.detail.value);
      return;
    }

    if (e.detail.checked) {
      setOrganizaciones([...Organizaciones, e.detail.value]);
    } else {
      if (comunidadPredeterminada === e.detail.value) {
        setComunidadPredeterminada("");
      }
      setOrganizaciones(Organizaciones.filter((f) => f !== e.detail.value));
    }
  };

  const handleRoleChange = (e: any) => {
    if (e.detail.checked) {
      setSelectedRoles([...selectedRoles, e.detail.value]);
    } else {
      setSelectedRoles(selectedRoles.filter((f) => f !== e.detail.value));
    }
  };

  const roles = ["Administrador", "Director", "Músico", "Cantante"];
  return (
    <>
      <IonContent scrollEvents={false}>
        <IonPage style={{ marginBottom: "100px" }}>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonMenuButton />
              </IonButtons>
              <IonTitle>Nuevo Usuario</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent scrollY={true}>
            <IonGrid style={{ width: "95%" }}>
              <IonRow className="ion-align-items-start">
                <IonCol>
                  <IonItem>
                    <IonLabel position="floating">Email</IonLabel>
                    <IonInput
                      type="email"
                      value={Correo}
                      onIonChange={(e) => setEmail(e.detail.value!)}
                    ></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="floating"> Contraseña</IonLabel>
                    <IonInput
                      type="password"
                      value={Password}
                      onIonChange={(e) => setPassword(e.detail.value!)}
                    ></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="floating"> Nombre Apellido</IonLabel>
                    <IonInput
                      type="text"
                      value={Nombre}
                      onIonChange={(e) => setNombre(e.detail.value!)}
                    ></IonInput>
                  </IonItem>
                  {/* <IonInput
                      type="text"
                      value={Organizacion}
                      onIonChange={(e) => setOrganizacion(e.detail.value!)}
                    ></IonInput> */}
                </IonCol>
              </IonRow>
              <IonRow style={{ marginTop: "0.5rem" }}>
                <IonCol>
                  <IonTitle>Miembro de:</IonTitle>
                  <IonContent
                    style={{
                      height: "20vh",
                      border: "1px solid gray",
                    }}
                  >
                    <IonList>
                      <IonRadioGroup
                        value={comunidadPredeterminada}
                        onIonChange={handleRadioChange}
                      >
                        {comunidadesTodas.map((c: any) => (
                          <React.Fragment key={c.id}>
                            <IonItem>
                              <IonLabel>{c.Nombre}</IonLabel>
                              <IonCheckbox value={c.id} radioGroup="grup" />
                              <IonRadio
                                style={{ marginLeft: "2rem" }}
                                value={c.id}
                              ></IonRadio>
                            </IonItem>
                          </React.Fragment>
                        ))}
                      </IonRadioGroup>
                    </IonList>
                  </IonContent>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <IonTitle>Roles:</IonTitle>
                  <IonContent
                    style={{
                      height: "20vh",
                      border: "1px solid gray",
                    }}
                  >
                    <IonList>
                      <IonRadioGroup onIonChange={handleRoleChange}>
                        {roles.map((c: any) => (
                          <React.Fragment key={c}>
                            <IonItem>
                              <IonLabel>{c}</IonLabel>
                              <IonCheckbox value={c} radioGroup="grup" />
                            </IonItem>
                          </React.Fragment>
                        ))}
                      </IonRadioGroup>
                    </IonList>
                  </IonContent>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <IonButton expand="block" onClick={(e) => handleLogin(e)}>
                    Crear nuevo usuario
                  </IonButton>
                  <div className="ion-padding">
                    <IonText color={"danger"}>
                      {userError ? userError : null}{" "}
                      {errorFormulario ? errorFormulario : null}
                    </IonText>
                    <IonText color={"success"}>
                      {nuevoUsuarioCreado
                        ? `Se creo el nuevo usuario:${nuevoUsuarioCreado.displayName}`
                        : ""}
                    </IonText>
                  </div>
                </IonCol>
              </IonRow>
              <IonButton
                fill="clear"
                expand="block"
                className="ion-margin-top"
                routerLink={"/"}
              >
                Volver
              </IonButton>
            </IonGrid>
          </IonContent>
        </IonPage>
      </IonContent>
    </>
  );
}
