import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { arrowBack } from "ionicons/icons";
import CancionCelebracion from "../components/Canciones/CancionCelebracion";
import CelebracionesContext from "../contextManagment/celebraciones/celebracionesContext";

const ContenidoCelebracionPage = (props: any) => {
  const history = useHistory();
  const { cancionCelebracionVisible } = useContext(CelebracionesContext);
  const [idCelebracion, setIdCelebracion] = useState<string>("");

  useEffect(() => {
    setIdCelebracion(cancionCelebracionVisible.IdCelebracion);
  }, [cancionCelebracionVisible]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              onClick={() => {
                history.goBack();
              }}
            >
              <IonIcon icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle className="ion-text-center">{`Contenido de ${idCelebracion}`}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <CancionCelebracion
          cancionLlega={cancionCelebracionVisible}
          mostrarAcorde={true}
          tamanioLetra={1.2}
          key={cancionCelebracionVisible.Id}
        />
      </IonContent>
    </IonPage>
  );
};

export default ContenidoCelebracionPage;
