export const validaCorreo = (correo) => {
  var regex =
    /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return regex.test(correo);
};

export const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radio de la Tierra en kilómetros
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;
  return distance;
};
function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

export const getLocation = async () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve(position.coords);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export const userTienePrivilegios = (userProfile, seccion) => {
  let retorno = false;
  if (!userProfile) return retorno;
  switch (seccion) {
    case "Mostrar swich":
      if (userProfile.Roles.includes("Cantante")) {
        retorno = false;
      }
      if (userProfile.Roles.includes("Músico")) {
        retorno = true;
      }
      if (userProfile.Roles.includes("Director")) {
        retorno = true;
      }
      if (userProfile.Roles.includes("Administrador")) {
        retorno = true;
      }
      break;
    case "Cambio Tonalidad":
      if (userProfile.Roles.includes("Cantante")) {
        retorno = false;
      }
      if (userProfile.Roles.includes("Músico")) {
        retorno = false;
      }
      if (userProfile.Roles.includes("Director")) {
        retorno = true;
      }
      if (userProfile.Roles.includes("Administrador")) {
        retorno = true;
      }
      break;
    case "Swich Inicia Encendido":
      if (userProfile.Roles.includes("Cantante")) {
        retorno = false;
      }
      if (userProfile.Roles.includes("Músico")) {
        retorno = true;
      }
      if (userProfile.Roles.includes("Director")) {
        retorno = true;
      }
      if (userProfile.Roles.includes("Administrador")) {
        retorno = true;
      }
      break;
    case "Mostrar abrir canción edit":
      if (userProfile.Roles.includes("Cantante")) {
        retorno = false;
      }
      if (userProfile.Roles.includes("Músico")) {
        retorno = false;
      }
      if (userProfile.Roles.includes("Director")) {
        retorno = true;
      }
      if (userProfile.Roles.includes("Administrador")) {
        retorno = true;
      }
      break;
  }

  return retorno;
};
