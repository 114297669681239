import {
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRange,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { play, headset, pause, list, refresh } from "ionicons/icons";
import React, { useContext, useEffect, useRef, useState } from "react";

import { getStorage, ref, listAll, getBlob } from "firebase/storage";
import CelebracionesContext from "../../contextManagment/celebraciones/celebracionesContext";
import { PlayerMultipista } from "./PlayerMultipista";
interface Props {
  idCancion: string;
  nombreCancion: string;
}

const ReproducirCancion = ({ idCancion, nombreCancion }: Props) => {
  const {
    reproduciendo,
    setReproduciendo,
    setReproductorAbierto,
    ArrReproductores,
  } = useContext(CelebracionesContext);

  const [tiempoTotal, setTiempoTotal] = useState(0);
  const [listArchivos, setListArchivos] = useState<string[] | []>([]);
  const [audios, setAudios] = useState<HTMLAudioElement[] | []>([]);
  const [cargarAudio, setCargarAudio] = useState(false);
  const [cargoArchivos, setCargoArchivos] = useState(false);
  const [segmentValue, setSegmentValue] = useState("pauseOutline");
  const [abrirReproduccion, setAbrirReproduccion] = useState(false);
  const [audioIsLoading, setAudioIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [btnPreciono, setBtnPreciono] = useState<string>(idCancion);
  const [abrirModalPistas, setAbrirModalPistas] = useState(false);
  const [resetVolumen, setResetVolumen] = useState(false);
  useEffect(() => {
    if (!cargoArchivos && abrirReproduccion) {
      //getListadoAudios();
      getAudiosCancion();
    }
  }, [abrirReproduccion, cargoArchivos]);

  useEffect(() => {
    ArrReproductores.forEach((element: any) => {
      if (!btnPreciono || !element) return;
      if (element.idCancion === btnPreciono) {
        setAbrirReproduccion(element.reproductorAbierto);
      } else {
        setAbrirReproduccion(false);
        desdeInicio();
      }
    });
  }, [ArrReproductores]);

  useEffect(() => {
    if (btnPreciono && btnPreciono != idCancion) setBtnPreciono("");
  }, [btnPreciono]);

  useEffect(() => {
    setTiempoTotal(audios.length ? audios[0].duration : 0);
    if (reproduciendo) {
      const interval = setInterval(() => {
        setProgress((prevProgress) => prevProgress + 0.1);
      }, 100);
      return () => {
        clearInterval(interval);
      };
    } else {
      pauseAudios();
    }
  }, [reproduciendo]);

  const getAudiosCancion = async () => {
    if (audios.length) return;
    const storage = getStorage();
    const listRef = ref(storage, `Audios/${idCancion}`);
    const list: string[] = [];

    await listAll(listRef)
      .then((res: any) => {
        res.items.forEach((itemRef: any) => {
          list.push(itemRef._location.path_);
        });
        setListArchivos(list);
      })
      .catch((error: any) => {
        // console.log("Error al llamar archivos:", error);
      });

    await cargarAudios(list);
    //(audios);
  };

  const cargarListaAudios = async (ruta: string) => {
    const storage = getStorage();
    const starsRef = ref(storage, ruta);
    let result = await getBlob(starsRef)
      .then((blob) => {
        let blobURL = window.URL.createObjectURL(blob);
        let audio = new Audio(blobURL);
        audio.preload = "auto";
        audio.id = ruta
          .replaceAll(`Audios/${idCancion}/`, "")
          .replaceAll(".ogg", "");
        return audio;
      })
      .catch((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        console.error(error);
        return new Audio();
      });
    return result;
  };
  const cargarAudios = async (list: string[]) => {
    setAudioIsLoading(true);
    let existRep = ArrReproductores?.find((element: any) => {
      if (!element) return false;
      return element.idCancion === idCancion;
    });
    // console.log("DATA REPRODUCIR", existRep);
    //Si los audios estan en stage no los vuelve a llamar
    if (existRep) {
      setAudios([...existRep.listAudios]);
      setAudioIsLoading(false);
      return;
    }
    try {
      const loadedAudios = await Promise.all(
        list.map(async (f) => {
          const aud = await cargarListaAudios(f);
          return aud;
        })
      );
      setAudios([...audios, ...loadedAudios]);
      let obj = {
        idRep: `rep-${idCancion}`,
        idCancion: idCancion,
        listAudios: [...loadedAudios],
        reproductorAbierto: true,
        progress: 0,
        reproducionEnCurso: false,
      };
      if (obj && idCancion && obj.idRep) {
        setReproductorAbierto(obj);
      }
      setCargarAudio(false);
      setAudioIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  //Llama un listado de audios desde la carpeta de la canción
  const getListadoAudios = async () => {
    setAudioIsLoading(true);
    if (audios.length) return;
    const storage = getStorage();
    const listRef = ref(storage, `Audios/${idCancion}`);
    const list: string[] = [];
    ///metodo que se utiliza despues de reaalizar el llamado de los nombres de los archivos
    const carga = async (listadoDearchivos: string[], largoArr: number) => {
      let existRep = ArrReproductores?.find((element: any) => {
        if (!element) return false;
        return element.idCancion === idCancion;
      });
      //Si los audios estan en stage no los vuelve a llamar
      if (existRep) {
        setAudios([...existRep.listAudios]);
        setAudioIsLoading(false);
        return;
      }

      if (listadoDearchivos.length) {
        setAudioIsLoading(true);
        let cont = 1;
        listadoDearchivos.forEach(async (f) => {
          const starsRef = ref(storage, f);
          await getBlob(starsRef)
            .then((blob) => {
              let blobURL = window.URL.createObjectURL(blob);
              let audio = new Audio(blobURL);
              audio.preload = "auto";
              audio.id = `audioId-${idCancion}-${cont}`;
              setAudios([...audios, audio]);
              if (cont === largoArr) {
                let obj = {
                  idRep: `rep-${idCancion}`,
                  idCancion: idCancion,
                  listAudios: [...audios, audio],
                  reproductorAbierto: true,
                  progress: 0,
                  reproducionEnCurso: false,
                };
                if (obj && idCancion && obj.idRep) {
                  setReproductorAbierto(obj);
                }
                setAudioIsLoading(false);
              }
              cont++;
            })
            .catch((error) => {
              // A full list of error codes is available at
              // https://firebase.google.com/docs/storage/web/handle-errors
              console.error(error);
            });
        });
      }
    };
    await listAll(listRef)
      .then((res: any) => {
        res.items.forEach((itemRef: any) => {
          list.push(itemRef._location.path_);
        });
        setListArchivos(list);
        carga(list, list.length);
      })
      .catch((error: any) => {
        console.log("Error al llamar archivos:", error);
      });

    setCargoArchivos(true);
    setCargarAudio(true);
  };

  const pauseAudios = async () => {
    setSegmentValue("pauseOutline");
    audios.forEach((a) => a.pause());
    await setReproduciendo(false);
  };
  const playAudios = (currentTime: number) => {
    if (!currentTime) {
      currentTime = 0;
    }
    setSegmentValue("playOutline");

    // let audiosRep = ArrReproductores.find((a: any) => a.idCancio === idCancion);
    // console.log("audiosRep: ", audiosRep);
    // if (!audiosRep) return;
    // audiosRep.listAudios.forEach((d: any) => {
    //   d.currentTime = currentTime;
    // });
    // audiosRep.listAudios.forEach((d: any) => {
    //   d.play();
    // });
    // setProgress(currentTime);
    // setReproduciendo(true);

    audios.forEach((a) => {
      a.currentTime = currentTime;
    });

    setProgress(currentTime);
    audios.forEach((a) => a.play());
    setReproduciendo(true);
  };
  const desdeInicio = () => {
    setSegmentValue("playSkipBackOutline");
    audios.forEach((a) => a.pause());
    audios.forEach((a) => (a.currentTime = 0));
    setProgress(0);
    setReproduciendo(false);
  };
  const HamgleIonKnobMoveStart = (event: any) => {
    pauseAudios();
  };
  const hangleIonKnobNoveEnd = async (event: any) => {
    playAudios(event.detail.value);
  };

  const manejarAbrirReproduccion = async (e: any) => {
    if (btnPreciono != idCancion) return;
    let repAbierto = ArrReproductores.find(
      (rep: any) => rep.idRep === `rep-${idCancion}`
    );
    if (abrirReproduccion) {
      ///si esta abierto lo cierra
      setReproduciendo(false);
      setAbrirReproduccion(false);
      if (repAbierto) repAbierto.reproductorAbierto = false;

      setReproductorAbierto(repAbierto);
      desdeInicio();
    } else {
      setReproduciendo(false);
      setAbrirReproduccion(true);
      if (repAbierto) repAbierto.reproductorAbierto = true;
      setReproductorAbierto(repAbierto);
      pauseAudios();
    }
  };

  //Para abrir modal con pistas
  const modalListPistas = useRef<HTMLIonModalElement>(null);
  function dismiss() {
    modalListPistas.current?.dismiss();
    setAbrirModalPistas(false);
  }

  return (
    <>
      <IonModal
        id={`modal-${idCancion}`}
        ref={modalListPistas}
        isOpen={abrirModalPistas}
        onWillDismiss={() => {
          setAbrirModalPistas(false);
          desdeInicio();
        }}
      >
        <IonContent>
          <IonToolbar>
            <IonTitle>{nombreCancion}</IonTitle>
            <IonButtons slot="end">
              <IonButton
                color="primary"
                onClick={() => {
                  dismiss();
                  desdeInicio();
                }}
              >
                Cerrar
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <IonToolbar>
            {reproduciendo ? (
              <IonButton
                slot="start"
                size="small"
                onClick={() => pauseAudios()}
              >
                <IonIcon icon={pause}></IonIcon>
              </IonButton>
            ) : audioIsLoading ? (
              <IonSpinner color="tertiary"></IonSpinner>
            ) : (
              <IonButton
                size="small"
                slot="start"
                onClick={() => playAudios(audios[0].currentTime)}
              >
                <IonIcon icon={play}></IonIcon>
              </IonButton>
            )}

            <IonRange
              min={0}
              max={tiempoTotal}
              onIonKnobMoveStart={HamgleIonKnobMoveStart}
              onIonKnobMoveEnd={hangleIonKnobNoveEnd}
              value={progress}
              ticks={false}
              onIonChange={(e: any) => {
                if (progress > tiempoTotal) {
                  desdeInicio();
                }
              }}
              snaps
              color="primary"
            />
            <IonButton
              slot="end"
              size="small"
              onClick={() => {
                resetVolumen ? setResetVolumen(false) : setResetVolumen(true);
              }}
            >
              <IonIcon icon={refresh}></IonIcon>
            </IonButton>
          </IonToolbar>

          <IonList>
            {audios.map((aud, index) => (
              <IonItem key={`item-${idCancion}-${index}`}>
                <PlayerMultipista
                  key={index}
                  audio={aud}
                  resetVolume={resetVolumen}
                />
              </IonItem>
            ))}
          </IonList>
        </IonContent>
      </IonModal>

      <IonFab
        activated={abrirReproduccion}
        onClick={async (e) => {
          setBtnPreciono(`${idCancion}`);
          await manejarAbrirReproduccion(e);
        }}
        style={{ margin: "5px" }}
      >
        <IonFabButton style={{ width: "37px", height: "37px" }}>
          <IonIcon icon={headset}></IonIcon>
        </IonFabButton>
      </IonFab>

      {abrirReproduccion ? (
        <div
          style={{
            display: "flex",
            height: "25px",
            width: "87%",
            marginBottom: "-5px",
            marginInlineStart: "45px",
            zIndex: "5",
            position: "relative",
            //alignContent: "end",
            //justifyContent: "flex-end", // Alineación a la derecha
          }}
        >
          {reproduciendo ? (
            <IonButton slot="start" size="small" onClick={() => pauseAudios()}>
              <IonIcon icon={pause}></IonIcon>
            </IonButton>
          ) : audioIsLoading ? (
            <IonSpinner color="tertiary"></IonSpinner>
          ) : (
            <IonButton
              size="small"
              slot="start"
              onClick={() => playAudios(audios[0].currentTime)}
            >
              <IonIcon icon={play}></IonIcon>
            </IonButton>
          )}
          <IonRange
            min={0}
            max={tiempoTotal}
            onIonKnobMoveStart={HamgleIonKnobMoveStart}
            onIonKnobMoveEnd={hangleIonKnobNoveEnd}
            value={progress}
            ticks={false}
            onIonChange={(e: any) => {
              if (progress > tiempoTotal) {
                desdeInicio();
              }
            }}
            color="primary"
          />
          {audios.length > 1 ? (
            <IonButton
              slot="end"
              size="small"
              onClick={() => setAbrirModalPistas(true)}
            >
              <IonIcon icon={list}></IonIcon>
            </IonButton>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default ReproducirCancion;
