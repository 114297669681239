import React, { useEffect, useState } from "react";

export const PlayerMultipista = ({ audio, resetVolume }) => {
  const [volume, setVolume] = useState(0.8); // Inicializar volumen en 50%
  useEffect(() => {
    setVolume(0.8);
    audio.volume = 0.8;
  }, [resetVolume]);
  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    audio.volume = newVolume;
  };

  return (
    <div style={{ width: "100%", display: "flex" }}>
      <div style={{ width: "60%" }}>{audio.id}</div>
      <div style={{ width: "40%" }}>
        <input
          type="range"
          min="0"
          max="1"
          step="0.1"
          value={volume}
          onChange={handleVolumeChange}
          style={{ width: "100%" }}
          onDoubleClick={() => {
            audio.volume = 0.8;
            setVolume(0.8);
          }}
        />
      </div>
    </div>
  );
};
