import {
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonInput,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonPage,
  IonContent,
  IonGrid,
  IonText,
} from "@ionic/react";

import { personCircle } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { validaCorreo } from "../Util/Utiles";

import UserContext from "../contextManagment/user/userContext";

export default function LoginForm() {
  const { userActive, loginUsuario, userError, LimpiarErrorFirebaseLogin } =
    useContext(UserContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorFormulario, setErrorFormulario] = useState("");

  useEffect(() => {
    if (userActive) {
      setTimeout(() => {
        window.location.href = "/";
      }, 500);
    }
  }, [userActive]);

  const handleLogin = async (e: any) => {
    setErrorFormulario("");
    LimpiarErrorFirebaseLogin();
    const loginData = {
      email: email,
      password: password,
    };

    if (!validaCorreo(loginData.email)) {
      setErrorFormulario("El correo no es valido");
      return;
    }
    if (!loginData.email) return;
    if (!loginData.password) return;
    //Al realizar el login se activa al usuario para mostrar menu desde el component app
    await loginUsuario(loginData);
  };

  return (
    <>
      <IonContent scrollEvents={false}>
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonMenuButton />
              </IonButtons>
              <IonTitle>Acceso</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonGrid>
            <IonRow className="texto-center">
              <IonCol>
                <IonIcon
                  style={{ fontSize: "70px", color: "#0040ff" }}
                  icon={personCircle}
                />
              </IonCol>
            </IonRow>
            <IonRow className="ion-align-items-start">
              <form>
                <IonCol>
                  <IonItem>
                    <IonLabel position="floating">Email</IonLabel>
                    <IonInput
                      type="email"
                      value={email}
                      onIonChange={(e) => setEmail(e.detail.value!)}
                    ></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="floating"> Contraseña</IonLabel>
                    <IonInput
                      type="password"
                      value={password}
                      onIonChange={(e) => setPassword(e.detail.value!)}
                    ></IonInput>
                  </IonItem>
                </IonCol>
              </form>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonButton expand="block" onClick={(e) => handleLogin(e)}>
                  Ingresar
                </IonButton>
                <IonText>
                  {userError ? userError : null}{" "}
                  {errorFormulario ? errorFormulario : null}
                </IonText>
              </IonCol>
            </IonRow>
            <IonButton
              fill="clear"
              expand="block"
              className="ion-margin-top"
              routerLink={"/"}
            >
              Volver
            </IonButton>
          </IonGrid>
        </IonPage>
      </IonContent>
    </>
  );
}
