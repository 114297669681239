import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonModal,
  IonRow,
  IonTextarea,
  IonToolbar,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import React, { useContext, useRef, useState, useEffect } from "react";

import { close } from "ionicons/icons";
import CelebracionesContext from "../../contextManagment/celebraciones/celebracionesContext";
import ICancionCelebracion from "../Interfaces/ICancionCelebracion";
import { useLocation } from "react-router";
import IAcorde from "../Interfaces/IAcorde";
interface Props {
  Contenido: ICancionCelebracion | null;
  idPresentacion: string;
  isOpen: boolean;
  setOpenClose: any;
  setAgrego: any;
  reordenarDesabilitado: boolean;
  setListadoEliminaOrdena: any;
  listadoEliminaOrdena: any;
}
const ModalContenido = ({
  Contenido,
  idPresentacion,
  isOpen,
  setOpenClose,
  setAgrego,
  reordenarDesabilitado,
  setListadoEliminaOrdena,
  listadoEliminaOrdena,
}: Props) => {
  const loc = useLocation();

  const {
    agregarContenidoBd,
    setCancionCelebracionVisible,
    acordes,
    GetAcordes,
  } = useContext(CelebracionesContext);
  const [present] = useIonAlert();
  const [presentToast] = useIonToast();
  const [nombreContenido, setNombreContenido] = useState<string>(
    Contenido ? Contenido.Nombre : ""
  );
  const modalAddContenido = useRef<HTMLIonModalElement>(null);
  const [contenido, setContenido] = useState<string>(
    Contenido ? Contenido.Letra : ""
  );
  useEffect(() => {
    if (isOpen) setOpenClose(false);
    if (!acordes.length) {
      GetAcordes();
    }
  }, [loc]);
  useEffect(() => {
    if (!Contenido) {
      setNombreContenido("");
      setContenido("");
    }
  }, [isOpen]);

  const advertirDescartarContenido = () => {
    present({
      // cssClass: 'my-css',
      header: "¡Atención!",
      message: "¿Quieres descartar los cambios realizados?",
      buttons: [
        "Volver",
        {
          text: "Descartar",
          handler: (d) => {
            setOpenClose(false);
            setContenido(Contenido ? Contenido.Letra : "");
            setNombreContenido(Contenido ? Contenido.Nombre : "");
          },
        },
      ],
      //onDidDismiss: (e) => console.log('did dismiss'),
    });
  };
  const agregarContenidoACelebracion = async () => {
    if (!contenido || !nombreContenido) {
      presentToast("Debes ingresar un nombre y un contenido.", 4000);
      return;
    }
    let cont = pasaAcordesToClass(contenido);
    let objContenidoListado = {
      Id: Contenido ? Contenido.Id : "",
      Ord: Contenido ? Contenido.Ord : "",
      Nombre: nombreContenido,
      SeccionOrden: "1",
      Seccion: "1",
      Letra: cont,
      AcordeBase: null,
      IdCelebracion: idPresentacion,
      TipoObj: "Contenido",
    };

    if (!reordenarDesabilitado && setListadoEliminaOrdena != null) {
      setListadoEliminaOrdena([...listadoEliminaOrdena, objContenidoListado]);
      setOpenClose(false);
    } else {
      present({
        // cssClass: 'my-css',
        header: "ACTUALIZAR!",
        message: "¿Quieres actualizar este contenido?",
        buttons: [
          "No",
          {
            text: "Sí",
            handler: (d) => {
              setOpenClose(false);
              agregarContenidoBd(objContenidoListado);
              setAgrego(true);
              setCancionCelebracionVisible(objContenidoListado);
            },
          },
        ],
      });
    }
  };
  const ordenarAcordesEvaluacion = (a: IAcorde, b: IAcorde) => {
    return a.OrdEval - b.OrdEval;
  };
  const pasaAcordesToClass = (cancionLimpia: string) => {
    if (!cancionLimpia) return;
    let lineas = cancionLimpia.split("\n");
    let arrProcesado: string[] = [];
    lineas.forEach((lin) => {
      acordes.sort(ordenarAcordesEvaluacion).forEach((ac: IAcorde) => {
        let palabras = lin.split(" ");
        let auxLinea: string[];
        auxLinea = palabras.map((p) => {
          let aux = p;
          if (p === ac.Id) {
            aux = `<span class="Acorde">${ac.Id}</span>`;
          } else {
            let variaciones = ac.Variaciones.split("-");
            variaciones.forEach((vari) => {
              if (p === vari) {
                aux = `<span class="Acorde">${ac.Id}</span>`;
              }
            });
          }
          return aux;
        });
        lin = auxLinea.join(" ");
      });
      arrProcesado.push(lin);
    });
    return arrProcesado.join("\n");
  };

  return (
    <IonModal
      ref={modalAddContenido}
      onWillDismiss={() => {
        setOpenClose(false);
        setAgrego(true);
      }}
      onIonModalWillPresent={() => {
        let letraString = Contenido?.Letra.replace(/(<([^>]+)>)/gi, "");
        setContenido(letraString ? letraString : "");
      }}
      isOpen={isOpen}
    >
      <IonHeader>
        <IonToolbar>
          <IonInput
            style={{ fontSize: "1.5rem" }}
            placeholder="Nombre del Contenido"
            value={nombreContenido}
            onIonChange={(e) =>
              setNombreContenido(e.detail.value ? e.detail.value : "")
            }
          />
          <IonButtons slot="end">
            <IonButton onClick={() => modalAddContenido.current?.dismiss()}>
              <IonIcon slot="end" icon={close} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonTextarea
          style={{
            width: "auto",
            marginTop: "0.5rem",
            marginLeft: "0.5rem",
            marginRight: "0.5rem",
          }}
          className="bordesSuaves preCancion"
          autoGrow={true}
          rows={16}
          value={contenido}
          placeholder="Escribe un nuevo contenido"
          //value={descripcionCancion}
          onIonChange={(e) =>
            setContenido(e.detail.value ? e.detail.value : "")
          }
        />
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol className="ion-text-center">
              <IonButton
                color="secondary"
                fill="outline"
                onClick={() => advertirDescartarContenido()}
              >
                Descartar
              </IonButton>
            </IonCol>
            <IonCol className="ion-text-center">
              <IonButton
                color="secondary"
                fill="outline"
                onClick={() => agregarContenidoACelebracion()}
              >
                {Contenido?.Id ? "Actualizar" : "Agregar"}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonModal>
  );
};

export { ModalContenido };
