import {
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonFooter,
  IonHeader,
  IonIcon,
  IonPage,
  IonRange,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import ICancion from "../components/Interfaces/ICancion";
import CelebracionesContext from "../contextManagment/celebraciones/celebracionesContext";
import parse from "html-react-parser";
import {
  arrowBack,
  playSkipBackOutline,
  playOutline,
  pauseOutline,
  create,
  chevronUp,
  chevronDown,
} from "ionicons/icons";
import { getStorage, ref, listAll, getBlob } from "firebase/storage";
import UserContext from "../contextManagment/user/userContext";
import { userTienePrivilegios } from "../Util/Utiles";
import IAcorde from "../components/Interfaces/IAcorde";

interface Props {
  forceUpdateCancionEscucha: number;
}

const CancionEscucha = ({ forceUpdateCancionEscucha }: Props) => {
  const { idCancion } = useParams<{ idCancion: string | undefined }>();
  const {
    canciones,
    reproduciendo,
    setReproduciendo,
    celebracionActiva,
    acordes,
  } = useContext(CelebracionesContext);
  const { profileUser } = useContext(UserContext);

  let locationRouter = useLocation();
  useEffect(() => {
    pauseAudios();
    setReproduciendo(false);
  }, [locationRouter]);
  const preHtml = useRef<HTMLElement>(null);
  const [cancion, setCancion] = useState<ICancion | null>(null);
  const history = useHistory();
  const [progress, setProgress] = useState(0);
  const [tiempoTotal, setTiempoTotal] = useState(0);
  const [listArchivos, setListArchivos] = useState<string[] | []>([]);
  const [audios, setAudios] = useState<HTMLAudioElement[] | []>([]);
  const [cargarAudio, setCargarAudio] = useState(false);
  const [cargoArchivos, setCargoArchivos] = useState(false);
  const [segmentValue, setSegmentValue] = useState("pauseOutline");
  const [acordeACambiar, setAcordeACambiar] = useState<String>("");
  useEffect(() => {
    if (!cancion || idCancion != cancion.Id) {
      setAudios([]);
      setListArchivos([]);
      setCargarAudio(false);
      setTiempoTotal(0);
      setCargoArchivos(false);
    }
    let c = canciones.find((c: ICancion) => c.Id == idCancion);
    setCancion(c);
    setAcordeACambiar(c?.AcordeBase);
  }, [idCancion]);

  useEffect(() => {
    if (
      !celebracionActiva &&
      history.location.pathname.indexOf("CancionEscucha") === -1
    ) {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    if (!cargoArchivos) {
      getListadoAudios();
    }
    if (listArchivos.length && cargarAudio) {
      cargarAudios();
    }
  }, [listArchivos, cargarAudio]);

  useEffect(() => {
    if (preHtml.current) preHtml.current.id = `id_${acordeACambiar}`;
  }, [acordeACambiar]);

  const getListadoAudios = () => {
    setAudios([]);
    const storage = getStorage();
    //const listRef = ref(storage, "Audios/39uxQtbHv2atG2ShBQdA");
    const listRef = ref(storage, `Audios/${idCancion}`);
    const list: string[] = [];
    listAll(listRef)
      .then((res: any) => {
        res.items.forEach((itemRef: any) => {
          list.push(itemRef._location.path_);
        });
        setListArchivos(list);
      })
      .catch((error: any) => {});
    setCargoArchivos(true);
    setCargarAudio(true);
  };

  const cargarAudios = async () => {
    try {
      const loadedAudios = await Promise.all(
        listArchivos.map(async (f) => {
          const aud = await cargarListaAudios(f);
          return aud;
        })
      );
      setAudios([...audios, ...loadedAudios]);
      setCargarAudio(false);
    } catch (error) {
      console.error(error);
    }
  };

  const cargarListaAudios = async (ruta: string) => {
    const storage = getStorage();
    const starsRef = ref(storage, ruta);
    let result = await getBlob(starsRef)
      .then((blob) => {
        let blobURL = window.URL.createObjectURL(blob);
        let audio = new Audio(blobURL);
        audio.preload = "auto";
        return audio;
      })
      .catch((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        console.error(error);
        return new Audio();
      });
    return result;
  };

  const playAudios = (currentTime: number) => {
    if (!currentTime) {
      currentTime = 0;
    }
    setSegmentValue("playOutline");
    audios.forEach((a) => {
      a.currentTime = currentTime;
    });
    setProgress(currentTime);
    audios.forEach((a) => {
      a.play();
    });
    setReproduciendo(true);
  };
  const pauseAudios = async () => {
    setSegmentValue("pauseOutline");
    audios.forEach((a) => a.pause());
    await setReproduciendo(false);
  };
  const desdeInicio = () => {
    setSegmentValue("playSkipBackOutline");
    audios.forEach((a) => a.pause());
    audios.forEach((a) => (a.currentTime = 0));
    setProgress(0);
    setReproduciendo(false);
  };

  useEffect(() => {
    setTiempoTotal(audios.length ? audios[0].duration : 0);

    if (reproduciendo) {
      const interval = setInterval(() => {
        setProgress((prevProgress) => prevProgress + 0.1);
      }, 100);
      return () => {
        clearInterval(interval);
      };
    }
  }, [reproduciendo]);

  const HamgleIonKnobMoveStart = (event: any) => {
    pauseAudios();
  };

  const hangleIonKnobNoveEnd = async (event: any) => {
    playAudios(event.detail.value);
  };
  const ordenarAcordesEvaluacion = (a: IAcorde, b: IAcorde) => {
    return a.OrdEval - b.OrdEval;
  };
  function ordenarEscala(a: IAcorde, b: IAcorde) {
    return a.Orden - b.Orden;
  }

  const transponerAcorde = (
    nroSemitono: number,
    acordeACambiar: string,
    positivoNegativo: number
  ) => {
    try {
      if (!acordeACambiar) return "";
      let acordeInicio = acordes.find((a: IAcorde) => a.Id === acordeACambiar);
      let arrAcorTipo: IAcorde[] = [];
      acordes.sort(ordenarAcordesEvaluacion).forEach((a: IAcorde) => {
        if (!a) return;
        if (a.Tipo === acordeInicio.Tipo) {
          arrAcorTipo.push(a);
        }
      });

      let escalaOrdenada = arrAcorTipo.sort(ordenarEscala);
      let acordeNew: IAcorde | undefined;
      let contSeguridad = 0;

      if (nroSemitono < 0) {
        nroSemitono = nroSemitono * -1;
      }

      do {
        let nroBuscado = acordeInicio.Orden + positivoNegativo;
        if (nroBuscado > 12) {
          nroBuscado = 1;
        }
        if (nroBuscado < 1) {
          nroBuscado = 12;
        }
        acordeNew = escalaOrdenada.find((a: IAcorde) => a.Orden === nroBuscado);

        contSeguridad++;
      } while (!acordeNew && contSeguridad < 120);
      return acordeNew ? acordeNew.Id : "";
    } catch (error) {
      return "error";
    }
  };

  const cambiarAlturaAcorde = (sumRest: number) => {
    let acor = acordes.find((a: IAcorde) => a.Id === acordeACambiar);
    let arrAcorTipo: IAcorde[] = [];
    acordes.forEach((a: IAcorde) => {
      if (a.Tipo === acor.Tipo) {
        arrAcorTipo.push(a);
      }
    });
    let acordeNew: IAcorde | undefined;
    if (sumRest === 1) {
      acordeNew = arrAcorTipo.find((a: IAcorde) => a.Orden === acor.Orden + 1);
      if (!acordeNew) {
        acordeNew = arrAcorTipo.find((a: IAcorde) => a.Orden === 1);
      }
    } else {
      acordeNew = arrAcorTipo.find((a: IAcorde) => a.Orden === acor.Orden + -1);
      if (!acordeNew) {
        acordeNew = arrAcorTipo.find((a: IAcorde) => a.Orden === 12);
      }
    }
    // let nroT = nroSemitonoTransponer(acordeBase, acordeNew ? acordeNew.Id : "");
    let oldAcc = acordes.find((a: IAcorde) => a.Id === acordeACambiar);
    if (!acordeNew) return;

    let sumaSemitono =
      oldAcc.Orden < acordeNew.Orden
        ? acordeNew.Orden - oldAcc.Orden
        : (oldAcc.Orden - acordeNew.Orden) * -1;
    preHtml.current?.querySelectorAll(".Acorde").forEach((e) => {
      e.innerHTML = transponerAcorde(sumaSemitono, e.innerHTML, sumRest);
    });
    setAcordeACambiar(acordeNew.Id);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              onClick={() => {
                if (reproduciendo) {
                  desdeInicio();
                }
                history.goBack();
              }}
            >
              <IonIcon icon={arrowBack} />
            </IonButton>
          </IonButtons>
          {userTienePrivilegios(profileUser, "Mostrar abrir canción edit") ? (
            <IonButtons slot="end">
              <IonButton
                onClick={() => {
                  history.push(`/page/CancionPage/${idCancion}`);
                }}
              >
                <IonIcon icon={create} />
              </IonButton>
            </IonButtons>
          ) : null}

          <IonTitle className="ion-text-center">{cancion?.Nombre}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <pre
          ref={preHtml as any}
          className="preCancion"
          style={{
            fontSize: `1.2rem`,
            lineHeight: "normal",
            margin: "0.8rem ",
            paddingBottom: "15px",
          }}
        >
          {parse(
            cancion && cancion.Cancion
              ? cancion.Cancion
              : "<p>No encontrada</p>"
          )}
        </pre>
        {preHtml.current?.querySelectorAll(".Acorde").length ? (
          <IonFab
            style={{ opacity: 0.5 }}
            slot="fixed"
            vertical="top"
            horizontal="end"
          >
            <IonFabButton size="small" onClick={() => cambiarAlturaAcorde(1)}>
              <IonIcon icon={chevronUp}></IonIcon>
            </IonFabButton>
            <IonFabButton size="small" onClick={() => cambiarAlturaAcorde(-1)}>
              <IonIcon icon={chevronDown}></IonIcon>
            </IonFabButton>
          </IonFab>
        ) : null}
      </IonContent>

      {audios.length > 0 && !cargarAudio ? (
        <IonFooter collapse="fade">
          <IonRange
            style={{ height: "30px" }}
            min={0}
            max={tiempoTotal}
            onIonKnobMoveStart={HamgleIonKnobMoveStart}
            onIonKnobMoveEnd={hangleIonKnobNoveEnd}
            value={progress}
            onIonChange={(e: any) => {
              if (progress > tiempoTotal) {
                pauseAudios();
              }
            }}
            color="primary"
          ></IonRange>
          <IonSegment value={segmentValue} color="medium">
            <IonSegmentButton
              onClick={() => {
                desdeInicio();
              }}
              value="playSkipBackOutline"
            >
              <IonIcon icon={playSkipBackOutline}></IonIcon>
            </IonSegmentButton>
            {reproduciendo ? (
              <IonSegmentButton
                value="pauseOutline"
                onClick={() => {
                  pauseAudios();
                }}
              >
                <IonIcon icon={pauseOutline}></IonIcon>
              </IonSegmentButton>
            ) : (
              <IonSegmentButton
                value="playOutline"
                onClick={() => {
                  playAudios(audios[0].currentTime);
                }}
              >
                <IonIcon icon={playOutline}></IonIcon>
              </IonSegmentButton>
            )}
          </IonSegment>
        </IonFooter>
      ) : null}
    </IonPage>
  );
};

export default CancionEscucha;
