import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonModal,
  IonProgressBar,
  IonRange,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  play,
  headset,
  pause,
  refresh,
  cloudUploadOutline,
  closeCircleOutline,
} from "ionicons/icons";
import React, { useContext, useEffect, useRef, useState } from "react";

import {
  getStorage,
  ref,
  listAll,
  getBlob,
  uploadBytes,
} from "firebase/storage";
import CelebracionesContext from "../../contextManagment/celebraciones/celebracionesContext";
import { PlayerMultipista } from "./PlayerMultipista";
interface Props {
  idCancion: string;
  nombreCancion: string;
  modalAbierta: boolean;
  setModalAbierta: Function;
}

const GestorAudios = ({
  idCancion,
  nombreCancion,
  modalAbierta,
  setModalAbierta,
}: Props) => {
  const {
    reproduciendo,
    setReproduciendo,
    setReproductorAbierto,
    ArrReproductores,
    setCancionTieneAudio,
  } = useContext(CelebracionesContext);

  const [tiempoTotal, setTiempoTotal] = useState(0);
  const [listArchivos, setListArchivos] = useState<string[] | []>([]);
  const [audios, setAudios] = useState<HTMLAudioElement[] | []>([]);
  const [cargarAudio, setCargarAudio] = useState(false);
  const [segmentValue, setSegmentValue] = useState("pauseOutline");
  const [abrirReproduccion, setAbrirReproduccion] = useState(false);
  const [audioIsLoading, setAudioIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [btnPreciono, setBtnPreciono] = useState<string>(idCancion);
  const [abrirModalPistas, setAbrirModalPistas] = useState(false);
  const [resetVolumen, setResetVolumen] = useState(false);
  const inputFile = useRef<any>(null);
  const [loadingUpload, setLoadingUpload] = useState(false);
  useEffect(() => {
    if (modalAbierta) {
      getAudiosCancion();
    }
  }, [modalAbierta]);

  // useEffect(() => {
  //   if (!cargoArchivos && abrirReproduccion) {
  //     //getListadoAudios();
  //     getAudiosCancion();
  //   }
  // }, [abrirReproduccion, cargoArchivos]);

  // useEffect(() => {
  //   ArrReproductores.forEach((element: any) => {
  //     if (!btnPreciono || !element) return;
  //     if (element.idCancion === btnPreciono) {
  //       setAbrirReproduccion(element.reproductorAbierto);
  //     } else {
  //       setAbrirReproduccion(false);
  //       desdeInicio();
  //     }
  //   });
  // }, [ArrReproductores]);

  // useEffect(() => {
  //   if (btnPreciono && btnPreciono != idCancion) setBtnPreciono("");
  // }, [btnPreciono]);

  useEffect(() => {
    setTiempoTotal(audios.length ? audios[0].duration : 0);
    if (reproduciendo) {
      const interval = setInterval(() => {
        setProgress((prevProgress) => prevProgress + 0.1);
      }, 100);
      return () => {
        clearInterval(interval);
      };
    } else {
      pauseAudios();
    }
  }, [reproduciendo]);

  const getAudiosCancion = async () => {
    if (audios.length) return;
    const storage = getStorage();
    const listRef = ref(storage, `Audios/${idCancion}`);
    const list: string[] = [];

    await listAll(listRef)
      .then((res: any) => {
        res.items.forEach((itemRef: any) => {
          list.push(itemRef._location.path_);
        });
        setListArchivos(list);
      })
      .catch((error: any) => {
        // console.log("Error al llamar archivos:", error);
      });

    await cargarAudios(list);
    //(audios);
  };

  const cargarListaAudios = async (ruta: string) => {
    const storage = getStorage();
    const starsRef = ref(storage, ruta);
    let result = await getBlob(starsRef)
      .then((blob) => {
        let blobURL = window.URL.createObjectURL(blob);
        let audio = new Audio(blobURL);
        audio.preload = "auto";
        audio.id = ruta
          .replaceAll(`Audios/${idCancion}/`, "")
          .replaceAll(".ogg", "");
        return audio;
      })
      .catch((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        console.error(error);
        return new Audio();
      });
    return result;
  };
  const cargarAudios = async (list: string[]) => {
    setAudioIsLoading(true);
    let existRep = ArrReproductores?.find((element: any) => {
      if (!element) return false;
      return element.idCancion === idCancion;
    });
    // console.log("DATA REPRODUCIR", existRep);
    //Si los audios estan en stage no los vuelve a llamar
    if (existRep) {
      setAudios([...existRep.listAudios]);
      setAudioIsLoading(false);
      return;
    }
    try {
      const loadedAudios = await Promise.all(
        list.map(async (f) => {
          const aud = await cargarListaAudios(f);
          return aud;
        })
      );
      setAudios([...audios, ...loadedAudios]);
      let obj = {
        idRep: `rep-${idCancion}`,
        idCancion: idCancion,
        listAudios: [...loadedAudios],
        reproductorAbierto: true,
        progress: 0,
        reproducionEnCurso: false,
      };
      if (obj && idCancion && obj.idRep) {
        setReproductorAbierto(obj);
      }
      setCargarAudio(false);
      setAudioIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  //Llama un listado de audios desde la carpeta de la canción
  const pauseAudios = async () => {
    setSegmentValue("pauseOutline");
    audios.forEach((a) => a.pause());
    await setReproduciendo(false);
  };
  const playAudios = (currentTime: number) => {
    if (!currentTime) {
      currentTime = 0;
    }
    setSegmentValue("playOutline");
    audios.forEach((a) => {
      a.currentTime = currentTime;
    });

    setProgress(currentTime);
    audios.forEach((a) => a.play());
    setReproduciendo(true);
  };
  const desdeInicio = () => {
    setSegmentValue("playSkipBackOutline");
    audios.forEach((a) => a.pause());
    audios.forEach((a) => (a.currentTime = 0));
    setProgress(0);
    setReproduciendo(false);
  };
  const HamgleIonKnobMoveStart = (event: any) => {
    pauseAudios();
  };
  const hangleIonKnobNoveEnd = async (event: any) => {
    playAudios(event.detail.value);
  };

  //Para abrir modal con pistas
  const modalListPistas = useRef<HTMLIonModalElement>(null);
  function dismiss() {
    modalListPistas.current?.dismiss();
    setAbrirModalPistas(false);
  }

  const openFileDialog = async () => {
    inputFile.current?.click();
  };
  const cargarAudiosANube = async (e: any) => {
    ///https://firebase.google.com/docs/storage/web/upload-files?hl=es-419
    setLoadingUpload(true);
    const storage = getStorage();
    let loadedAudios: HTMLAudioElement[] = [];
    for (let index = 0; index < e.target.files.length; index++) {
      const element = e.target.files[index];
      const storageRef = ref(
        storage,
        "Audios/" + idCancion + "/" + element.name
      );

      uploadBytes(storageRef, element).then(async (snapshot) => {
        let audio: HTMLAudioElement = await readFileAsAudio(element);
        setAudios([...audios, audio]);
        loadedAudios.push(audio);
      });
    }

    let obj = {
      idRep: `rep-${idCancion}`,
      idCancion: idCancion,
      listAudios: [...loadedAudios],
      reproductorAbierto: true,
      progress: 0,
      reproducionEnCurso: false,
    };
    if (obj && idCancion && obj.idRep) {
      setReproductorAbierto(obj);
    }
    setCargarAudio(false);
    setAudioIsLoading(false);

    setCancionTieneAudio(idCancion, true);
    setLoadingUpload(false);
    inputFile.current.value = "";
    getAudiosCancion();
  };

  const readFileAsAudio = (file: any) => {
    return new Promise<HTMLAudioElement>((resolve, reject) => {
      const audio = new Audio();
      audio.onloadeddata = (event: any) => {
        resolve(audio);
      };
      audio.onerror = (error) => {
        reject(error);
      };
      audio.src = URL.createObjectURL(file);
    });
  };

  return (
    <IonModal
      id={`modal-${idCancion}`}
      ref={modalListPistas}
      isOpen={modalAbierta}
      onWillDismiss={() => {
        setModalAbierta(false);
        desdeInicio();
      }}
    >
      <IonHeader>
        <input
          ref={inputFile}
          type="file"
          accept="audio/*"
          multiple
          onChange={cargarAudiosANube}
          style={{ display: "none" }}
        />
        <IonToolbar>
          <IonTitle style={{ textAlign: "center", fontWeight: "bold" }}>
            Gestor de audios
          </IonTitle>
          <IonButtons slot="end" style={{ marginRight: "1rem" }}>
            <IonIcon
              size="large"
              onClick={() => {
                dismiss();
                desdeInicio();
              }}
              icon={closeCircleOutline}
            ></IonIcon>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonTitle style={{ marginTop: "1.3rem" }}>{nombreCancion}</IonTitle>
        {audios && audios.length ? (
          <IonToolbar style={{ margin: "auto", width: "80%" }}>
            {reproduciendo ? (
              <IonButton
                slot="start"
                size="small"
                onClick={() => pauseAudios()}
              >
                <IonIcon icon={pause}></IonIcon>
              </IonButton>
            ) : audioIsLoading ? (
              <IonSpinner color="tertiary"></IonSpinner>
            ) : (
              <IonButton
                size="small"
                slot="start"
                onClick={() => playAudios(audios[0].currentTime)}
              >
                <IonIcon icon={play}></IonIcon>
              </IonButton>
            )}

            <IonRange
              min={0}
              max={tiempoTotal}
              onIonKnobMoveStart={HamgleIonKnobMoveStart}
              onIonKnobMoveEnd={hangleIonKnobNoveEnd}
              value={progress}
              ticks={false}
              onIonChange={(e: any) => {
                if (progress > tiempoTotal) {
                  desdeInicio();
                }
              }}
              snaps
              color="primary"
            />
            <IonButton
              slot="end"
              size="small"
              onClick={() => {
                resetVolumen ? setResetVolumen(false) : setResetVolumen(true);
              }}
            >
              <IonIcon icon={refresh}></IonIcon>
            </IonButton>
          </IonToolbar>
        ) : null}

        <IonList>
          {audios.map((aud, index) => (
            <IonItem key={`item-${idCancion}-${index}`}>
              <PlayerMultipista
                key={index}
                audio={aud}
                resetVolume={resetVolumen}
              />
            </IonItem>
          ))}
          {!loadingUpload ? (
            <IonItem color={"light"} key={`item-${idCancion}-add}`}>
              <IonText>Agegar un nuevo audio</IonText>
              <IonIcon
                slot="end"
                onClick={openFileDialog}
                icon={cloudUploadOutline}
              ></IonIcon>
            </IonItem>
          ) : (
            <IonProgressBar type="indeterminate"></IonProgressBar>
          )}
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default GestorAudios;
