import {
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToggle,
  IonToolbar,
  ScrollDetail,
} from "@ionic/react";
import { logIn, musicalNotesOutline } from "ionicons/icons";
import { createRef, useContext, useEffect, useState } from "react";
import CelebracionesContext from "../contextManagment/celebraciones/celebracionesContext";
import UserContext from "../contextManagment/user/userContext";
import Celebracion from "../components/Celebracion/Celebracion";
import A_mas from "../img/Amas.svg";
import A_menos from "../img/Amenos.svg";
import { userTienePrivilegios } from "../Util/Utiles";
interface Props {
  org: string | null;
}
const CelebracionVisible = ({ org }: Props) => {
  ///CONTEXT
  const { userActive, profileUser, setProfileUser } = useContext(UserContext);
  const {
    mostrarLoading,
    celebracionActiva,
    llamarCelebracionActiva,
    usuarioComunidad,
    setComunidadByLocation,
    userLocation,
    setUsuarioComunidad,
    // updateCancionTieneAudio
    //   cargaAcordes
    //MigrarBD
    //UpdateCancionesDePresentaciones,
  } = useContext(CelebracionesContext);
  ///STATE INTERNOS
  const [mostrarAcorde, setMostrarAcorde] = useState(
    userTienePrivilegios(profileUser, "Swich Inicia Encendido")
  );
  const [scrollTop, setScrollTop] = useState(0);
  const [tamanioLetra, setTamanioLetra] = useState<number>(1.15);
  const contentRef = createRef<HTMLIonContentElement>();
  //const [organizacion, setOrganizacion] = useState("");
  //const { orgParam } = useParams<{ orgParam: string | undefined }>();

  // useEffect(() => {
  //   if (orgParam) {
  //     setOrganizacion(orgParam);
  //     SetComunidadActiva(orgParam);
  //   } else if (org) {
  //     setOrganizacion(org);
  //     SetComunidadActiva(org);
  //   } else {
  //     setOrganizacion("JEM");
  //     SetComunidadActiva("JEM");
  //   }
  //   setUsuarioComunidad(userActive?.photoURL);
  // }, [orgParam, org]);

  useEffect(() => {
    if (
      usuarioComunidad &&
      celebracionActiva?.IdComunidad != usuarioComunidad
    ) {
      //setOrganizacion(usuarioComunidad);
      llamarCelebracionActiva(usuarioComunidad);
      setProfileUser();
    } else if (!usuarioComunidad && !mostrarLoading) {
      // console.log("ELSE from CelebracionActiva", usuarioComunidad);
      setComunidadByLocation();
    }
  }, [usuarioComunidad, userLocation]);

  useEffect(() => {
    //console.log("PASA  LOCATION");
    if (userLocation === "user") {
      // setOrganizacion(userActive?.photoURL);
      llamarCelebracionActiva(userActive?.photoURL);
      setUsuarioComunidad(userActive?.photoURL);
    } else {
      setComunidadByLocation();
    }
  }, [userLocation]);

  useEffect(() => {
    scrollTocustom(scrollTop);
  }, [celebracionActiva]);

  function handleScroll(ev: CustomEvent<ScrollDetail>) {
    setScrollTop(ev.detail.scrollTop);
  }
  const scrollTocustom = async (topScroll: number) => {
    await contentRef.current?.scrollToPoint(0, topScroll, 100);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonRow>
            <IonCol>
              <IonButtons>
                <IonMenuButton />
              </IonButtons>
            </IonCol>
            <IonCol>
              <IonItem lines="none">
                {userTienePrivilegios(profileUser, "Mostrar swich") ? (
                  <>
                    <IonIcon icon={musicalNotesOutline} />
                    <IonToggle
                      checked={mostrarAcorde}
                      onClick={() => setMostrarAcorde(!mostrarAcorde)}
                    ></IonToggle>
                  </>
                ) : null}
              </IonItem>
            </IonCol>
            <IonCol>
              <div
                style={{
                  marginTop: "0.4rem",
                  verticalAlign: "middle",
                  display: "inline-flex",
                }}
              >
                <IonImg
                  style={{
                    width: "2rem",
                    height: "2rem",
                  }}
                  src={A_menos}
                  onClick={() => setTamanioLetra(tamanioLetra - 0.08)}
                />{" "}
                <IonImg
                  style={{ width: "2rem", height: "2rem" }}
                  src={A_mas}
                  onClick={() => setTamanioLetra(tamanioLetra + 0.08)}
                  className="btn"
                />
              </div>
              {/* <button onClick={updateCancionTieneAudio}>
                Update
              </button> */}
            </IonCol>
          </IonRow>
          {!userActive ? (
            <IonItem slot="start" lines="none" routerLink={"/Login"}>
              <IonIcon icon={logIn} />
            </IonItem>
          ) : null}
        </IonToolbar>
      </IonHeader>

      <IonHeader collapse="condense">
        <IonToolbar>
          <IonTitle size="large">Celebración</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent
        ref={contentRef}
        scrollY={true}
        onIonScroll={handleScroll}
        scrollEvents={true}
      >
        {celebracionActiva && !mostrarLoading ? (
          <Celebracion
            tamanioLetra={tamanioLetra}
            key={celebracionActiva.Id}
            celebracion={celebracionActiva}
            mostrarAcorde={mostrarAcorde}
            esVistaCelebracionActiva={true}
          />
        ) : mostrarLoading ? (
          <IonLoading
            translucent={true}
            isOpen={mostrarLoading}
            message={"Espera un momento..."}
          />
        ) : (
          <IonRow style={{ marginTop: "2.5rem", marginLeft: "-0.6rem" }}>
            {" "}
            <IonTitle>Actualmente no existen celebraciones</IonTitle>
          </IonRow>
        )}
      </IonContent>
    </IonPage>
  );
};

export default CelebracionVisible;
