import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonFab,
  IonFabButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonThumbnail,
  useIonToast,
} from "@ionic/react";
import { add } from "ionicons/icons";
import { useContext, useState, useEffect } from "react";
import CelebracionesContext from "../../contextManagment/celebraciones/celebracionesContext";
import ICancion from "../Interfaces/ICancion";
import IGrupoCanciones from "../Interfaces/IGrupoCanciones";

interface Props {
  grupo: IGrupoCanciones;
  idCelebracion: string | undefined;
  listaPresente: string[] | [];
  fn_Agrego: any;
  reordenarDesabilitado: boolean;
  setListadoEliminaOrdena: any;
  listadoEliminaOrdena: any;
}

const CardGrupo = ({
  grupo,
  idCelebracion,
  listaPresente,
  fn_Agrego,
  reordenarDesabilitado,
  setListadoEliminaOrdena,
  listadoEliminaOrdena,
}: Props) => {
  const { addCancionesListado, canciones } = useContext(CelebracionesContext);
  const [grupoMostrar, setGrupoMostrar] = useState<IGrupoCanciones | null>(
    null
  );
  const [cancionesGrupo, setCancionesGrupo] = useState<ICancion[] | null>(null);

  const [presentToast] = useIonToast();

  useEffect(() => {
    //console.log("EFX 1 GRUPO");
    if (!grupoMostrar) {
      setGrupoMostrar(grupo);
    }
  });

  useEffect(() => {
    if (!cancionesGrupo) {
      let addArray: ICancion[] = [];
      let listaPresenteObj: ICancion[] = [];

      listaPresente.forEach((f) => {
        let ca = JSON.parse(f);
        listaPresenteObj.push(ca);
      });
      let arrNombre = listaPresenteObj
        ? listaPresenteObj.map((f) => f.Nombre)
        : [];
      addArray = grupo.CancionesDelGrupo?.filter((d) => {
        return !arrNombre.includes(d.Nombre);
      });
      if (addArray) setCancionesGrupo(addArray);
    }
  }, [cancionesGrupo]);

  const agregarACelebracion = (
    Id: string,
    idCelebracion: string | undefined
  ) => {
    if (!reordenarDesabilitado) {
      let can = canciones.find((ca: ICancion) => ca.Id === Id);
      can.TipoObj = "Cancion";
      setListadoEliminaOrdena([...listadoEliminaOrdena, can]);
    } else {
      addCancionesListado(Id, idCelebracion);
    }

    let cancionAgregar: ICancion[] = cancionesGrupo
      ? cancionesGrupo.filter((ca) => ca.Id === Id)
      : [];

    let actualizaGrupo: ICancion[] = cancionesGrupo
      ? cancionesGrupo.filter((ca) => ca.Id !== Id)
      : [];
    setCancionesGrupo(actualizaGrupo);
    fn_Agrego(true);

    presentToast({
      message: `Se agregó al listado ${cancionAgregar[0].Nombre}`,
      duration: 1500,
      position: "bottom",
    });
  };

  return (
    <IonCard color={"light"}>
      <img alt={grupo.NombreGrupo} src={grupo.ImgUrl} />
      <IonCardHeader>
        <IonCardTitle>{grupo.NombreGrupo}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonList>
          {cancionesGrupo ? (
            cancionesGrupo.map((ca: ICancion, index) => (
              <>
                <IonItem key={index}>
                  <IonThumbnail slot="end">
                    <IonFab horizontal="end">
                      <IonFabButton
                        size="small"
                        onClick={() =>
                          agregarACelebracion(ca.Id, idCelebracion)
                        }
                      >
                        <IonIcon icon={add} />
                      </IonFabButton>
                    </IonFab>
                  </IonThumbnail>
                  <IonLabel>
                    <h2 style={{ fontWeight: "500" }}>{ca.Nombre}</h2>
                    <p style={{ fontSize: "smaller" }}>{ca.Descripcion}</p>
                  </IonLabel>
                  {/* <IonLabel>{ca.Nombre}</IonLabel> */}
                </IonItem>
              </>
            ))
          ) : (
            <IonItem>No existen canciones en este grupo.</IonItem>
          )}
        </IonList>
      </IonCardContent>
    </IonCard>
  );
};

export default CardGrupo;
