import {
  IonBadge,
  IonButton,
  IonCard,
  IonCardContent,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  useIonAlert,
} from "@ionic/react";
import { cubeOutline, musicalNote, reload } from "ionicons/icons";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import CelebracionesContext from "../../contextManagment/celebraciones/celebracionesContext";
import UserContext from "../../contextManagment/user/userContext";
import { userTienePrivilegios } from "../../Util/Utiles";
import { ModalContenido } from "../Contenido";
import IAcorde from "../Interfaces/IAcorde";
import ICancionCelebracion from "../Interfaces/ICancionCelebracion";
import ReproducirCancion from "./ReproducirCancion";
import ICancion from "../Interfaces/ICancion";

interface Props {
  cancionLlega: ICancionCelebracion;
  mostrarAcorde?: boolean;
  tamanioLetra: number;
  esVistaCelebracionActiva?: boolean;
}

const CancionCelebracion = ({
  cancionLlega,
  mostrarAcorde,
  tamanioLetra,
  esVistaCelebracionActiva,
}: Props) => {
  const {
    acordes,
    GetAcordes,
    setCancionDeCelebracion,
    cancionCelebracionVisible,
    Comunidad,
    usuarioComunidad,
    canciones,
    getCancionesTodas,
    recargarDesdeBancoCanciones,
  } = useContext(CelebracionesContext);

  const { userActive, profileUser } = useContext(UserContext);
  const [cancion, setCancion] = useState<ICancionCelebracion>(cancionLlega);
  const history = useHistory();
  const [nroTransp, setNroTransp] = useState(0);

  const preHtml = useRef<HTMLElement>(null);
  const divAux = useRef<HTMLElement>(null);
  const modalAcordes = useRef<HTMLIonModalElement>(null);
  const [openModalAcorde, setOpenModalAcorde] = useState(false);
  const [acordeBase, setAcordeBase] = useState<string>(cancion.AcordeBase);
  const [positivoNegativo, setPositivoNegativo] = useState(0);
  const [present] = useIonAlert();
  const [isModalContenidoOpen, setIsModalContenidoOpen] =
    useState<boolean>(false);
  const [agrego, setAgrego] = useState<boolean>(false);
  //const [abrirReproduccion, setAbrirReproduccion] = useState<boolean>(false);

  useEffect(() => {
    LimpiarTodosLosAcordes(mostrarAcorde);
  }, [cancion, mostrarAcorde]);

  useEffect(() => {
    if (preHtml.current === null) return;
    if (nroTransp === 0) return;
    preHtml.current.querySelectorAll(".Acorde").forEach((e) => {
      e.innerHTML = transponerAcorde(nroTransp, e.innerHTML);
    });
  }, [acordeBase, nroTransp]);

  useEffect(() => {
    if (agrego) {
      setCancion(cancionCelebracionVisible);
      setAgrego(false);
      console.log("PASA POR EFX cancionCelebracionVisible");
    }
  }, [cancionCelebracionVisible]);

  const LimpiarTodosLosAcordes = (mostrarAcorde?: boolean) => {
    if (preHtml.current === null) return;
    preHtml.current.innerHTML = cancion.Letra;

    let lineas = cancion.Letra.split("\n");
    let lineasAux: string[] = [];
    if (!mostrarAcorde) {
      lineas.forEach((l) => {
        if (divAux.current) divAux.current.innerHTML = l;
        if (divAux) {
          let tieneAcorde = divAux.current?.querySelectorAll(".Acorde");
          if (!tieneAcorde?.length) {
            lineasAux.push(l);
          }
          if (divAux.current) {
            divAux.current.innerHTML = "";
          }
        }
      });

      preHtml.current.innerHTML = lineasAux.length ? lineasAux.join("\n") : "";
    }
  };

  const cambiarAlturaAcorde = (sumRest: number) => {
    let acor = acordes.find((a: IAcorde) => a.Id === acordeBase);
    let arrAcorTipo: IAcorde[] = [];
    acordes.forEach((a: IAcorde) => {
      if (a.Tipo === acor.Tipo) {
        arrAcorTipo.push(a);
      }
    });
    let acordeNew: IAcorde | undefined;
    if (sumRest === 1) {
      acordeNew = arrAcorTipo.find((a: IAcorde) => a.Orden === acor.Orden + 1);
      if (!acordeNew) {
        acordeNew = arrAcorTipo.find((a: IAcorde) => a.Orden === 1);
      }
    } else {
      acordeNew = arrAcorTipo.find((a: IAcorde) => a.Orden === acor.Orden + -1);
      if (!acordeNew) {
        acordeNew = arrAcorTipo.find((a: IAcorde) => a.Orden === 12);
      }
    }

    let nroT = nroSemitonoTransponer(acordeBase, acordeNew ? acordeNew.Id : "");
    setPositivoNegativo(sumRest);
    setNroTransp(nroT);

    setAcordeBase(acordeNew ? acordeNew.Id : "");
  };

  function ordenarEscala(a: IAcorde, b: IAcorde) {
    return a.Orden - b.Orden;
  }
  const ordenarAcordesEvaluacion = (a: IAcorde, b: IAcorde) => {
    return a.OrdEval - b.OrdEval;
  };

  const transponerAcorde = (nroSemitono: number, acordeACambiar: string) => {
    try {
      if (!acordeACambiar) return "";
      let acordeInicio = acordes.find((a: IAcorde) => a.Id === acordeACambiar);
      let arrAcorTipo: IAcorde[] = [];
      acordes.sort(ordenarAcordesEvaluacion).forEach((a: IAcorde) => {
        if (!a) return;
        if (a.Tipo === acordeInicio.Tipo) {
          arrAcorTipo.push(a);
        }
      });

      let escalaOrdenada = arrAcorTipo.sort(ordenarEscala);
      let acordeNew: IAcorde | undefined;
      let contSeguridad = 0;

      if (nroSemitono < 0) {
        nroSemitono = nroSemitono * -1;
      }

      do {
        let nroBuscado = acordeInicio.Orden + positivoNegativo;
        if (nroBuscado > 12) {
          nroBuscado = 1;
        }
        if (nroBuscado < 1) {
          nroBuscado = 12;
        }
        acordeNew = escalaOrdenada.find((a: IAcorde) => a.Orden === nroBuscado);

        contSeguridad++;
      } while (!acordeNew && contSeguridad < 120);
      return acordeNew ? acordeNew.Id : "";
    } catch (error) {
      return "error";
    }
  };

  const nroSemitonoTransponer = (acordeO: string, acordeN: string) => {
    let oldAcc = acordes.find((a: IAcorde) => a.Id === acordeO);
    let newAcc = acordes.find((a: IAcorde) => a.Id === acordeN);
    let sumaSemitono =
      oldAcc.Orden < newAcc.Orden
        ? newAcc.Orden - oldAcc.Orden
        : (oldAcc.Orden - newAcc.Orden) * -1;
    return sumaSemitono;
  };

  const abrirModalCambioAcorde = () => {
    if (Comunidad !== usuarioComunidad) return;
    if (!userTienePrivilegios(profileUser, "Cambio Tonalidad")) return;
    if (!acordes.length) {
      GetAcordes();
    }
    setOpenModalAcorde(true);
  };

  const guardarCambiosAcordes = async () => {
    if (!userActive) {
      setOpenModalAcorde(false);
      return false;
    }

    if (
      !history.location.pathname.includes("CancionDeCelebracion") &&
      history.location.pathname !== "/"
    ) {
      if (!history.location.pathname.includes(userActive.photoURL)) {
        setOpenModalAcorde(false);
        return false;
      }
    }

    present({
      // cssClass: 'my-css',
      header: "¡Atención!",
      message: `¿Quieres mantener estos acordes en la celebración?`,
      buttons: [
        {
          text: "Cancelar",
          handler: async () => {
            setAcordeBase(cancionLlega.AcordeBase);
            setCancion(cancionLlega);
            if (preHtml.current === null) return;
            preHtml.current.innerHTML = cancionLlega.Letra;
            setNroTransp(0);
          },
        },
        {
          text: "Guardar",
          handler: (d) => {
            let cancionGuardar = { ...cancion };
            cancionGuardar.AcordeBase = acordeBase;
            cancionGuardar.Letra = preHtml.current
              ? preHtml.current.innerHTML
              : "";
            setCancionDeCelebracion(cancionGuardar);
          },
        },
      ],
    });

    setOpenModalAcorde(false);
  };

  const abrirEdicionContenido = () => {
    if (cancion.TipoObj === "Contenido" && Comunidad === userActive.photoURL) {
      setIsModalContenidoOpen(true);
    }
  };

  const recargarFromBancoCanciones = async (idCancion: string) => {
    // let cancionBanco = await canciones.find(
    //   (c: ICancion) => c.Id === idCancion
    // );

    // if (!cancionBanco || !canciones || canciones.length == 0) {
    //   getCancionesTodas();
    //   return;
    // }
    present({
      // cssClass: 'my-css',
      header: "¡Atención!",
      message: `¿Quieres recargar la canción desde el banco de canciones?`,
      buttons: [
        {
          text: "Cancelar",
          handler: async () => {
            return false;
          },
        },
        {
          text: "Recargar",
          handler: async (d) => {
            if (preHtml.current === null) return;
            let cancionGuardar = { ...cancion };
            let cancionBanco = await recargarDesdeBancoCanciones(
              cancionGuardar
            );
            preHtml.current.innerHTML = cancionBanco.Cancion;
            setCancion(cancionGuardar);
            setAcordeBase(cancionBanco.AcordeBase);
            return;
          },
        },
      ],
    });
  };

  return (
    <>
      {cancion.Id && cancion.TieneAudio && userActive ? (
        <ReproducirCancion
          key={`rep-${cancion.Id}`}
          idCancion={cancion.Id}
          nombreCancion={cancion.Nombre}
        ></ReproducirCancion>
      ) : null}

      <IonCard
        style={{
          margin: "0.3rem 0rem 2rem 0rem",
        }}
      >
        <IonItem color="light" lines="none">
          {cancion.TipoObj !== "Contenido" ? (
            <>
              {!esVistaCelebracionActiva ? (
                <>
                  <IonIcon icon={musicalNote} slot="start" />
                  <IonIcon
                    slot="end"
                    icon={reload}
                    onClick={() => recargarFromBancoCanciones(cancion.Id)}
                  />
                </>
              ) : (
                <IonIcon icon={musicalNote} slot="start" />
              )}
            </>
          ) : (
            <IonIcon
              icon={cubeOutline}
              slot="start"
              onClick={() => abrirEdicionContenido()}
            />
          )}

          <IonLabel style={{ fontSize: `${tamanioLetra}rem` }}>
            {cancion.Nombre}
          </IonLabel>

          {mostrarAcorde ? (
            <IonBadge
              onClick={() => abrirModalCambioAcorde()}
              color={"secondary"}
            >
              {acordeBase}
            </IonBadge>
          ) : null}
        </IonItem>
        <IonCardContent>
          <pre
            style={{
              fontSize: `${tamanioLetra}rem`,
              lineHeight: "normal",
              margin: "0.2rem -0.3rem 0rem -0.3rem ",
              paddingBottom: "15px",
            }}
            ref={preHtml as any}
            className="preCancion"
          ></pre>
          <div ref={divAux as any}></div>
        </IonCardContent>
      </IonCard>

      <IonModal
        id="modalAcordes"
        ref={modalAcordes}
        isOpen={openModalAcorde}
        onWillDismiss={() => {
          guardarCambiosAcordes();
        }}
      >
        <IonContent>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "1rem 3rem 0 3rem ",
            }}
          >
            <IonButton onClick={() => cambiarAlturaAcorde(-1)}>-</IonButton>
            <p>{acordeBase}</p>
            <IonButton onClick={() => cambiarAlturaAcorde(1)}>+</IonButton>
          </div>
        </IonContent>
      </IonModal>

      <ModalContenido
        Contenido={cancion}
        idPresentacion={cancion.IdCelebracion}
        isOpen={isModalContenidoOpen}
        setAgrego={setAgrego}
        setOpenClose={setIsModalContenidoOpen}
        key={cancion.Id}
        reordenarDesabilitado={false}
        setListadoEliminaOrdena={null}
        listadoEliminaOrdena={[]}
      />
    </>
  );
};

export default CancionCelebracion;
