import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import { arrowBack } from "ionicons/icons";
import CancionCelebracion from "../components/Canciones/CancionCelebracion";
import CelebracionesContext from "../contextManagment/celebraciones/celebracionesContext";

const CancionCelebracionPage = (props: any) => {
  const history = useHistory();

  const { cancionCelebracionVisible } = useContext(CelebracionesContext);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              onClick={() => {
                history.goBack();
              }}
            >
              <IonIcon icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle className="ion-text-center">
            Canción de la Celebración
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <CancionCelebracion
          cancionLlega={cancionCelebracionVisible}
          mostrarAcorde={true}
          tamanioLetra={1.2}
        />
      </IonContent>
    </IonPage>
  );
};

export default CancionCelebracionPage;
