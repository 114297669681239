import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonModal,
  IonButton,
  IonInput,
  IonRow,
  IonCol,
  IonBadge,
  IonText,
  IonLoading,
} from "@ionic/react";
//import { useParams } from "react-router";
import "./Generic.css";
import UserContext from "../contextManagment/user/userContext";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import CelebracionesContext from "../contextManagment/celebraciones/celebracionesContext";
import ICelebracion from "../components/Interfaces/ICelebracion";
import { radio, add, close } from "ionicons/icons";
// interface ICelebracionesView {
//   listadoCelebraciones: ICelebracion[];
// }
const Celebraciones: React.FC = () => {
  const { userActive } = useContext(UserContext);
  const {
    listadoCelebraciones,
    actualizarListado,
    getListadoCelebraciones,
    crearListadoBd,
    mostrarLoading,
    setActualizarListado,
  } = useContext(CelebracionesContext);
  const history = useHistory();
  const modalCelebraciones = useRef<HTMLIonModalElement>(null);
  const [mostrarModalCelebraciones, setMostrarModalCelebraciones] =
    useState<boolean>(false);
  const [nombreListado, setNombreListado] = useState<string>("");
  const [descripcionListado, setDescripcionListado] = useState<string>("");
  const [errorCrear, setErrorCrear] = useState<string>("");

  useEffect(() => {
    if (actualizarListado && !mostrarLoading) {
      getListadoCelebraciones();
      setActualizarListado(false);
    }
  }, [actualizarListado]);

  const crearListado = () => {
    //verificar si existe
    let existe = listadoCelebraciones.find((p: any) => p.Id === nombreListado);
    if (!existe) {
      let presentacion = {
        Nombre: nombreListado,
        Descripción: descripcionListado,
        Activa: false,
        Canciones: [],
      };
      crearListadoBd(presentacion);
      setMostrarModalCelebraciones(false);
      history.push("/page/celebraciones");
    } else {
      setErrorCrear("El listado ya existe");
    }
  };
  return (
    <IonPage>
      <IonLoading
        translucent={true}
        isOpen={mostrarLoading}
        message={"Espera un momento..."}
      />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonIcon
            slot="end"
            className="ion-margin-end"
            icon={add}
            onClick={() => setMostrarModalCelebraciones(true)}
          />
          <IonTitle>Celebraciones</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {userActive ? (
            listadoCelebraciones.length > 0 ? (
              listadoCelebraciones
                .sort((a: any, b: any) => b.UpdateDate - a.UpdateDate)
                .map((c: ICelebracion) => (
                  <IonItem
                    key={c.Id}
                    //routerLink={`/page/CelebracionPage/${c.Id}`}
                    onClick={() => {
                      history.push(`/page/CelebracionPage/${c.Id}`);
                    }}
                    color={c.Activa ? "secondary" : ""}
                  >
                    {/* <div>
                      <IonLabel>{c.Nombre}</IonLabel>
                      <p
                        style={{
                          padding: "0px",
                          margin: "0px",
                          color: "lightgray",
                          fontSize: "0.8rem",
                        }}
                      >
                        {c.Descripción}
                      </p>
                    </div> */}
                    <IonLabel>
                      <h2 style={{ fontWeight: "500" }}>{c.Nombre}</h2>
                      <p style={{ fontSize: "smaller", fontStyle: "italic" }}>
                        {c.Descripción}
                      </p>
                    </IonLabel>

                    {c.Activa ? <IonIcon slot="start" icon={radio} /> : null}
                    <IonBadge slot="end" color="medium">
                      {c.Canciones?.length}
                    </IonBadge>
                  </IonItem>
                ))
            ) : (
              <IonItem>
                <IonLabel>No se encontraron celebraciones</IonLabel>
              </IonItem>
            )
          ) : (
            <IonLoading
              translucent={true}
              isOpen={mostrarLoading}
              message={"Espera un momento..."}
            />
          )}
        </IonList>
      </IonContent>

      <IonModal
        ref={modalCelebraciones}
        // trigger="open-edit"
        onWillDismiss={() => {
          setNombreListado("");
          setDescripcionListado("");
          setErrorCrear("");
          setMostrarModalCelebraciones(false);
        }}
        isOpen={mostrarModalCelebraciones}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton
                onClick={() => {
                  setDescripcionListado("");
                  setNombreListado("");
                  setErrorCrear("");
                  modalCelebraciones.current?.dismiss();
                }}
              >
                <IonIcon slot="end" icon={close} />
              </IonButton>
            </IonButtons>
            <IonTitle className="ion-text-center">Nuevo Listado</IonTitle>
            {/* <IonButtons slot="end">
                <IonButton strong={true} onClick={() => console.log("Confirm")}>
                  Confirm
                </IonButton>
              </IonButtons> */}
          </IonToolbar>
        </IonHeader>

        <IonContent className="ion-padding">
          <IonRow>
            <IonCol className="ion-align-self-center">
              <IonItem lines="full">
                <IonLabel position="stacked">
                  <h2>NOMBRE DEL LISTADO</h2>{" "}
                  <IonText color={"danger"}>{errorCrear}</IonText>
                </IonLabel>
                <IonInput
                  value={nombreListado}
                  onIonChange={(e) => {
                    setNombreListado(`${e.target.value}`);
                  }}
                >
                  {" "}
                </IonInput>
              </IonItem>
              <IonItem lines="full">
                <IonLabel position="stacked">
                  <h2>DESCRIPCIÓN DEL LISTADO</h2>
                </IonLabel>
                <IonInput
                  value={descripcionListado}
                  onIonChange={(e) => {
                    setDescripcionListado(`${e.target.value}`);
                  }}
                >
                  {" "}
                </IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow></IonRow>
          <IonRow>
            <IonCol className="ion-align-self-center">
              <IonButton
                onClick={() => setMostrarModalCelebraciones(false)}
                expand="full"
                color="light"
              >
                Cancelar
              </IonButton>
            </IonCol>
            <IonCol>
              <IonButton
                onClick={() => crearListado()}
                expand="full"
                color="light"
              >
                Crear
              </IonButton>
            </IonCol>
          </IonRow>
        </IonContent>
      </IonModal>
    </IonPage>
  );
};

export default Celebraciones;
