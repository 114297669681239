import {
  IonApp,
  IonLoading,
  IonRouterOutlet,
  IonSpinner,
  IonSplitPane,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Route } from "react-router-dom";
import Menu from "./components/Menu";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
/* Theme variables */
import "./theme/variables.css";
import Celebraciones from "./pages/Celebraciones";
import LoginForm from "./pages/LoginForm";
import { useContext, useState } from "react";
import LogOut from "./pages/LogOut";
import UserContext from "./contextManagment/user/userContext";
import CelebracionVisible from "./pages/CelebracionVisible";
import CelebracionPage from "./pages/CelebracionPage";
import BancoCancionesPage from "./pages/BancoCancionesPage";
import ListCancionesGrupo from "./pages/ListCancionesGrupo";
import CancionPage from "./pages/CancionPage";
import CreacionUsuarioPage from "./pages/CreacionUsuarioPage";
import "../src/pages/Generic.css";
import CancionEscucha from "./pages/CancionEscucha";
import CancionCelebracionPage from "./pages/CancionCelebracionPage";
import ContenidoCelebracionPage from "./pages/ContenidoCelebracionPage";
setupIonicReact();

const App: React.FC = () => {
  const { userActive, loadingFromUser } = useContext(UserContext);
  const [forceUpdateCancionEscucha, setForceUpdateCancionEscucha] = useState(0);

  function SistemaRutas() {
    return (
      <IonReactRouter>
        <IonSplitPane contentId="main">
          {userActive ? (
            <Menu
              userCorreo={userActive.email}
              organizacion={userActive.photoURL}
            />
          ) : null}

          <IonRouterOutlet id="main">
            <Route path="/" exact={true}>
              <CelebracionVisible
                org={userActive?.photoURL ? userActive?.photoURL : null}
              />
            </Route>
            <Route path="/Login" exact={true}>
              <LoginForm />
            </Route>

            <Route path="/page/CreacionUsuarioPage" exact={true}>
              <CreacionUsuarioPage />
            </Route>

            <Route path="/page/Celebraciones" exact={true}>
              <Celebraciones />
            </Route>

            <Route path="/page/BancoCancionesPage" exact={true}>
              <BancoCancionesPage />
            </Route>

            <Route path="/page/LogOut" exact={true}>
              <LogOut />
            </Route>

            <Route
              path={`/page/CelebracionPage/:id`}
              component={CelebracionPage}
              exact={true}
            />

            <Route
              path={`/page/ListCancionesGrupo/:idGrupo`}
              component={ListCancionesGrupo}
              exact={true}
            />

            <Route
              path="/page/CancionPage/:id"
              render={() => (
                <CancionPage
                  forceUpdateCancionEscucha={forceUpdateCancionEscucha}
                  setForceUpdateCancionEscucha={setForceUpdateCancionEscucha}
                />
              )}
            />
            <Route
              path={`/page/CancionDeCelebracion`}
              component={CancionCelebracionPage}
            />

            <Route
              path={`/page/ContenidoCelebracion`}
              component={ContenidoCelebracionPage}
            />
            <Route
              path={`/page/CancionEscucha/:idCancion`}
              render={() => (
                <CancionEscucha
                  forceUpdateCancionEscucha={forceUpdateCancionEscucha}
                />
              )}
            />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    );
  }

  return <IonApp>{loadingFromUser ? <IonSpinner /> : <SistemaRutas />}</IonApp>;
};

export default App;
