import { getAuth } from "firebase/auth";
import React, { useContext, useEffect, useState} from "react";
import UserContext from "../contextManagment/user/userContext";

const LogOut = () => {

  const { loginOutUser } = useContext(UserContext);

  useEffect(() => {
    salir();
    window.history.replaceState({},'','/')
  })
  const salir = async ()=> {
    await loginOutUser();
   }

    return (null);
  
} 
 
export default LogOut;

