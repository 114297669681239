import { IonCol, IonGrid, IonRow, IonTitle } from "@ionic/react";
import ICelebracion from "../Interfaces/ICelebracion";
import CancionCelebracion from "../Canciones/CancionCelebracion";
import "./estiloCelebracion.css";
import ICancionCelebracion from "../Interfaces/ICancionCelebracion";
import { useContext } from "react";
import CelebracionesContext from "../../contextManagment/celebraciones/celebracionesContext";

interface Props {
  celebracion: ICelebracion;
  tamanioLetra: number;
  mostrarAcorde: boolean;
  esVistaCelebracionActiva?: boolean;
}
const Celebracion = ({
  celebracion,
  tamanioLetra,
  mostrarAcorde,
  esVistaCelebracionActiva,
}: Props) => {
  const { comunidadesTodas, usuarioComunidad } =
    useContext(CelebracionesContext);

  const NombreComunidadActiva = () => {
    if (comunidadesTodas && usuarioComunidad) {
      return comunidadesTodas.find((d: any) => d.id === celebracion.IdComunidad)
        ?.Nombre;
    }
  };

  return (
    <IonGrid>
      <IonRow>
        <IonCol>
          <IonTitle style={{ fontWeight: "bold" }} className="ion-text-center">
            {NombreComunidadActiva()}
          </IonTitle>
          <IonTitle className="ion-text-center">
            {celebracion.Descripción}
          </IonTitle>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="tamanioLetra">
          {celebracion.ListCancionesObj?.map(
            (c: ICancionCelebracion, index) => {
              return (
                <CancionCelebracion
                  cancionLlega={c}
                  key={index}
                  mostrarAcorde={mostrarAcorde}
                  tamanioLetra={tamanioLetra}
                  esVistaCelebracionActiva={esVistaCelebracionActiva}
                />
              );
            }
          )}
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default Celebracion;
