//// Users
export const SET_USER_ACTIVE = "SET_USER_ACTIVE";
export const SET_USER_ERROR = "SET_USER_ERROR";
export const SET_LOADING_USER = "SET_LOADING_USER";
export const SET_USER_CREADO = "SET_USER_CREADO";
export const SET_PROFILE_USER = "SET_PROFILE_USER";

//Celebraciones

export const GET_CELEBRACION_ACTIVA = "GET_CELEBRACION_ACTIVA";
export const SET_LISTADO_CELEBRACIONES = "SET_LISTADO_CELEBRACIONES";
export const ELIMINAR_LISTADO = "ELIMINAR_LISTADO";

export const MOSTRAR_LOADING = "MOSTRAR_LOADING";
export const ACTIVAR_LISTA = "ACTIVAR_LISTA";

export const CREAR_LISTADO = "CREAR_LISTADO";
export const GET_GRUPOS = "GET_GRUPOS";
export const GET_CANCIONES = "GET_CANCIONES";
export const ADD_CANCION_LISTADO = "ADD_CANCION_LISTADO";
export const SET_CANCIONES_GRUPO = "SET_CANCIONES_GRUPO";
export const MODIFICO_LISTADO = "MODIFICO_LISTADO";
export const SET_CANCIONES_GRUPOS = "SET_CANCIONES_GRUPOS";
export const GUARDA_CANCION = "GUARDA_CANCION";
export const SET_CANCION_AGREGANDO = "SET_CANCION_AGREGANDO";
export const SET_ACORDES_TODOS = "SET_ACORDES_TODOS";

export const SET_CANCION_EDIT = "SET_CANCION_EDIT";
export const ACTIVAR_ESCUCHA = "ACTIVAR_ESCUCHA";
export const ACTIVAR_ESCUCHA_PRESENTACIONES = "ACTIVAR_ESCUCHA_PRESENTACIONES";
export const SET_ACTUALIZAR_LISTADO = "SET_ACTUALIZAR_LISTADO";
export const SET_REPRODUCIR = "SET_REPRODUCIR";
export const SET_LOCATION_PATH = "SET_LOCATION_PATH";
export const SET_CANCIONCELEBRACIONVISIBLE = "SET_CANCIONCELEBRACIONVISIBLE";
export const UPDATE_CELEBRACION = "UPDATE_CELEBRACION";
export const MODIFICA_CELEBRACION = "MODIFICA_CELEBRACION";
export const SET_COMUNIDAD = "SET_COMUNIDAD";
export const SET_USUARIO_COMUNIDAD = "SET_USUARIO_COMUNIDAD";
export const SET_COMUNIDADES_TODAS = "SET_COMUNIDADES_TODAS";
export const SET_REPRODUCTOR_ABIERTO = "SET_REPRODUCTOR_ABIERTO";
export const SET_USER_LOCATION = "SET_USER_LOCATION";
export const SET_CANCION_TIENEAUDIO = "SET_CANCION_TIENEAUDIO";
