import CelebracionesReducer from "./celebracionesReducer";
import CelebracionesContext from "./celebracionesContext";

import {
  MOSTRAR_LOADING,
  GET_CELEBRACION_ACTIVA,
  SET_LISTADO_CELEBRACIONES,
  GET_GRUPOS,
  GET_CANCIONES,
  SET_CANCION_AGREGANDO,
  GUARDA_CANCION,
  SET_ACORDES_TODOS,
  SET_CANCION_EDIT,
  ACTIVAR_ESCUCHA,
  ACTIVAR_ESCUCHA_PRESENTACIONES,
  SET_ACTUALIZAR_LISTADO,
  SET_REPRODUCIR,
  SET_CANCIONCELEBRACIONVISIBLE,
  MODIFICA_CELEBRACION,
  SET_COMUNIDAD,
  SET_USUARIO_COMUNIDAD,
  SET_COMUNIDADES_TODAS,
  SET_REPRODUCTOR_ABIERTO,
  SET_USER_LOCATION,
  SET_CANCION_TIENEAUDIO,
} from "../types";
import React, { useReducer } from "react";
import { getLocation, calculateDistance } from "../../Util/Utiles";
import {
  getFirestore,
  collection,
  getDocs,
  setDoc,
  doc,
  query,
  where,
  Timestamp,
  deleteDoc,
  updateDoc,
  onSnapshot,
  addDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import uuid from "react-native-uuid";
//import { getStorage, listAll, ref } from "firebase/storage";
var unsuscribePresentaciones;
var unsuscribePresentacionActiva;
const CelebracionesState = (props) => {
  const initialState = {
    celebracionActiva: null,
    listadoCelebraciones: [],
    canciones: [],
    mostrarLoading: false,
    grupoCanciones: [],
    modificoCelebracion: false,
    Comunidad: "",
    cancionAgregadaError: "",
    cancionAgregando: false,
    acordes: [],
    idCancionAEditar: "",
    seActivoEscucha: false,
    seActivoEscuchaPresentaciones: false,
    actualizarListado: true,
    reproduciendo: false,
    cancionCelebracionVisible: "",
    seModificaCelebracion: false,
    usuarioComunidad: "",
    comunidadesTodas: [],
    ArrReproductores: [],
    userLocation: "location",
  };
  const [state, dispatch] = useReducer(CelebracionesReducer, initialState);
  ////Metodos de escucha
  const escuchaPresentacionActiva = () => {
    const db = getFirestore();
    if (!state.Comunidad) return;

    if (unsuscribePresentacionActiva) {
      unsuscribePresentacionActiva();
    }

    const q = query(
      collection(db, `Presentaciones`),
      where("Activa", "==", true),
      where("IdComunidad", "==", state.Comunidad)
    );
    unsuscribePresentacionActiva = onSnapshot(q, (querySnapshot) => {
      mostrarLoadingFc(true);
      const presentaciones = [];
      ///TENER PRESENTE QUE SOLO LLEGA LA ACTIVA

      querySnapshot.forEach((doc) => {
        let canObj = [];
        doc.data().Canciones.forEach((ca) => {
          let obj = JSON.parse(ca);
          obj.IdCelebracion = doc.id;
          canObj.push(obj);
        });

        presentaciones.push({
          Id: doc.id,
          Canciones: doc.data().Canciones,
          Descripción: doc.data().Descripción,
          CreateDate: doc.data().CreateDate.toDate(),
          UpdateDate: doc.data().UpdateDate
            ? doc.data().UpdateDate.toDate()
            : doc.data().CreateDate.toDate(),
          Activa: doc.data().Activa,
          ListCancionesObj: [...canObj],
          IdComunidad: doc.data().IdComunidad,
        });
      });

      // console.log("PASA POR ESCUCHA PRES ACTIVA", presentaciones);
      ///localStorage.setItem("listadoCelebraciones", JSON.stringify(setLocal)); //LISTADO COMPLETO
      //console.log("presentaciones[0]", presentaciones[0]);
      //SOLO INCORPORAR LA ACTIVA

      dispatch({
        type: GET_CELEBRACION_ACTIVA,
        payload: presentaciones[0],
      });
    });
  };

  const escuchaPresentaciones = () => {
    if (unsuscribePresentaciones) unsuscribePresentaciones();
    const db = getFirestore();
    const queryPresentaciones = query(
      collection(db, `Presentaciones`),
      where("IdComunidad", "==", `${state.Comunidad}`) //
    );
    const unsuscribe = onSnapshot(queryPresentaciones, (snapshot) => {
      let presentaciones = [];
      snapshot.forEach((doc) => {
        if (doc.data().Descripción) {
          presentaciones.push({
            Id: doc.id,
            Nombre: doc.data().Nombre,
            Canciones: doc.data().Canciones,
            Descripción: doc.data().Descripción,
            CreateDate: doc.data().CreateDate.toDate(),
            UpdateDate: doc.data().UpdateDate
              ? doc.data().UpdateDate.toDate()
              : doc.data().CreateDate.toDate(),
            Activa: doc.data().Activa,
            IdComunidad: doc.data().IdComunidad,
          });
        }
      });
      console.log("ESCUCHA PRESENTACIONES");

      if (presentaciones.length > 0) {
        presentaciones.forEach((p) => {
          let canObj = [];
          p.Canciones.forEach((c) => {
            let obj = JSON.parse(c);
            obj.IdCelebracion = p.Id;
            canObj.push(obj);
          });
          p.ListCancionesObj = [...canObj];
        });

        let presOrdenada = presentaciones.sort(
          (a, b) => new Date(b.UpdateDate) - new Date(a.UpdateDate)
        );

        dispatch({
          type: SET_LISTADO_CELEBRACIONES,
          payload: presOrdenada,
        });
      } else {
        let presOrdenada = presentaciones.sort((a, b) => {
          return b.UpdateDate - a.UpdateDate;
        });
        dispatch({
          type: SET_LISTADO_CELEBRACIONES,
          payload: presOrdenada,
        });
        mostrarLoadingFc(false);
      }
    });
    unsuscribePresentaciones = unsuscribe;
  };

  ///Metodos a ser dispuestos
  const mostrarLoadingFc = async (mostrar) => {
    dispatch({
      type: MOSTRAR_LOADING,
      payload: mostrar,
    });
  };

  const SetComunidadActiva = async (org) => {
    dispatch({
      type: SET_COMUNIDAD,
      payload: org,
    });
  };

  const setEscuchaActiva = (activar) => {
    dispatch({
      type: ACTIVAR_ESCUCHA,
      payload: activar,
    });
  };

  const llamarCelebracionActiva = async (org) => {
    escuchaPresentacionActiva();
    setEscuchaActiva(true);
  };

  const getListadoCelebraciones = async () => {
    mostrarLoadingFc(true);
    escuchaPresentaciones();
    dispatch({
      type: ACTIVAR_ESCUCHA_PRESENTACIONES,
    });
  };

  const setActualizarListado = (actualizar) => {
    dispatch({
      type: SET_ACTUALIZAR_LISTADO,
      payload: actualizar,
    });
  };

  const eliminarListadoById = async (id) => {
    mostrarLoadingFc(true);
    const db = getFirestore();
    await deleteDoc(doc(db, `Presentaciones`, id)).then(() => {
      console.log("Presentación eliminada: ", id);
    });
  };

  const activarListadoById = async (id) => {
    mostrarLoadingFc(true);
    const db = getFirestore();
    let activaActual = state.listadoCelebraciones.find((a) => a.Activa);
    if (activaActual) {
      const presentacionRef = doc(db, "Presentaciones", activaActual.Id);
      await updateDoc(presentacionRef, {
        Activa: false,
      });
    }
    const presentacionRef2 = doc(db, "Presentaciones", id);

    await updateDoc(presentacionRef2, {
      Activa: true,
      UpdateDate: Timestamp.fromDate(new Date()),
    });
  };

  const crearListadoBd = async (presentacion) => {
    mostrarLoadingFc(true);
    const db = getFirestore();
    presentacion.CreateDate = Timestamp.fromDate(new Date());
    presentacion.UpdateDate = Timestamp.fromDate(new Date());
    presentacion.IdComunidad = state.Comunidad;
    try {
      const docRef = await addDoc(
        collection(db, "Presentaciones"),
        presentacion
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getGruposBd = async () => {
    mostrarLoadingFc(true);
    const db = getFirestore();
    let grupos = [];
    const querySnapshotGrup = await getDocs(collection(db, "GrupoCanciones"));
    querySnapshotGrup.forEach((doc) => {
      let gru = doc.data();
      gru.Id = doc.id;
      gru.CancionesDelGrupo = [];
      grupos.push(gru);
    });
    //Ordenar
    let grupoOrdenado = grupos.sort((a, b) => {
      return a.Orden - b.Orden;
    });
    dispatch({
      type: GET_GRUPOS,
      payload: grupoOrdenado,
    });
  };

  const getcancionesTodas = async () => {
    mostrarLoadingFc(true);
    const db = getFirestore();
    const q = query(collection(db, "Canciones"), where("Grupo", "!=", ""));
    const querySnapshot = await getDocs(q);
    let canciones = [];

    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      let objCancion = doc.data();
      objCancion.FechaActualizacion = objCancion.FechaActualizacion.toDate();
      objCancion.FechaCreacion = objCancion.FechaCreacion.toDate();
      objCancion.Id = doc.id;
      canciones.push(objCancion);
    });

    dispatch({
      type: GET_CANCIONES,
      payload: canciones,
    });
  };

  const addCancionesListado = async (idCancion, idListado) => {
    mostrarLoadingFc(true);
    if (!idCancion && !idListado) return;

    //BUSCAR CANCIONES DEL LISTADO
    let listado = state.listadoCelebraciones.find((l) => l.Id === idListado);
    let cancionAdd = state.canciones.find((c) => c.Id === idCancion);
    if (!listado | !cancionAdd) return;

    let objCancionListado = {
      Id: cancionAdd.Id,
      Ord: listado.Canciones.length,
      Nombre: cancionAdd.Nombre,
      SeccionOrden: "1",
      Seccion: cancionAdd.Seccion,
      Letra: cancionAdd.Cancion,
      AcordeBase: cancionAdd.AcordeBase,
      IdCelebracion: idListado,
      TipoObj: "Cancion",
      TieneAudio: cancionAdd.TieneAudio,
    };
    if (!listado.Canciones) listado.Canciones = [];
    listado.Canciones.push(JSON.stringify(objCancionListado));

    const db = getFirestore();
    const presentacionRef = doc(db, "Presentaciones", idListado);
    await updateDoc(presentacionRef, {
      UpdateDate: Timestamp.fromDate(new Date()),
      Canciones: listado.Canciones,
      Descripción: listado.Descripción,
    });

    // localStorage.removeItem("listadoCelebraciones");
    // localStorage.setItem(
    //   "listadoCelebraciones",
    //   JSON.stringify(state.listadoCelebraciones)
    // );

    // dispatch({
    //   type: ADD_CANCION_LISTADO,
    //   payload: listado,
    // });
  };

  const agregarContenidoBd = async (contenido) => {
    mostrarLoadingFc(true);
    if (!contenido || !contenido.Letra || !contenido.Nombre) {
      mostrarLoadingFc(false);
      return;
    }

    //BUSCAR CANCIONES DEL LISTADO
    let listado = state.listadoCelebraciones.find(
      (l) => l.Id === contenido.IdCelebracion
    );
    ///en caso que state.listadoCelebraciones este vacio nos referimos al listado activo
    if (!state.listadoCelebraciones.length) {
      listado = state.celebracionActiva;
    }

    let contenidoAdd = listado.ListCancionesObj.find(
      (c) => c.Id === contenido.Id
    );

    if (contenidoAdd) {
      let inx = listado.Canciones.findIndex((c) => c.includes(contenido.Id));
      listado.Canciones.splice(inx, 1, JSON.stringify(contenido));
    } else {
      contenido.Id = uuid.v4();
      contenido.Ord = listado.Canciones.length + 1;
      listado.Canciones.push(JSON.stringify(contenido));
    }

    const db = getFirestore();
    const presentacionRef = doc(db, "Presentaciones", contenido.IdCelebracion);
    await updateDoc(presentacionRef, {
      Canciones: listado.Canciones,
      UpdateDate: Timestamp.fromDate(new Date()),
    });

    setSeModificaCelebracion(true);

    mostrarLoadingFc(false);
  };

  const ordenarEliminardeCelebracion = async (
    listCancionesOrdenadas,
    idCelebracion,
    detallesCelebracion
  ) => {
    mostrarLoadingFc(true);
    if (!idCelebracion) return;

    //BUSCAR Celebración
    let listado = state.listadoCelebraciones.find(
      (l) => l.Id === idCelebracion
    );
    let listOrdenado = [];

    listCancionesOrdenadas.forEach((obj, inx) => {
      let cancionAdd = listado.ListCancionesObj.find((c) => c.Id === obj.Id);
      if (!cancionAdd && obj.TipoObj === "Contenido") {
        cancionAdd = { ...obj };
        cancionAdd.Id = uuid.v4();
        cancionAdd.Ord = inx;
      } else if (!cancionAdd && obj.TipoObj === "Cancion") {
        let cancionEncontrada = state.canciones.find((c) => c.Id === obj.Id);
        cancionAdd = { ...cancionEncontrada };
        cancionAdd.Letra = cancionEncontrada.Cancion;
      }
      let objCancionListado = {
        Id: cancionAdd.Id,
        Ord: inx,
        Nombre: cancionAdd.Nombre,
        SeccionOrden: 1,
        Seccion: cancionAdd.Seccion,
        Letra: cancionAdd.Letra,
        AcordeBase: cancionAdd.AcordeBase,
        IdCelebracion: idCelebracion,
        TipoObj: cancionAdd.TipoObj,
        TieneAudio: cancionAdd.TieneAudio,
      };
      if (!listado.Canciones) listado.Canciones = [];
      listOrdenado.push(JSON.stringify(objCancionListado));
    });
    listado.Canciones = [...listOrdenado];

    const db = getFirestore();
    const presentacionRef = doc(db, "Presentaciones", idCelebracion);
    await updateDoc(presentacionRef, {
      Canciones: listado.Canciones,
      Descripción: detallesCelebracion.descripcion,
      Nombre: detallesCelebracion.nombreCelebracion,
      UpdateDate: Timestamp.fromDate(new Date()),
    });
  };

  const guardaCancion = async (cancion) => {
    mostrarLoadingFc(true);
    cancion.FechaCreacion = Timestamp.fromDate(new Date());
    cancion.FechaActualizacion = Timestamp.fromDate(new Date());
    cancion.Creador = `${getAuth().currentUser.displayName}`;
    cancion.Organizacion = `${getAuth().currentUser.photoURL}`;
    const db = getFirestore();

    let existeCancion = state.canciones.find((c) => c.Id == cancion.Id);

    if (existeCancion) {
      const cancionRef = doc(db, "Canciones", cancion.Id);
      await setDoc(cancionRef, cancion);
    } else {
      try {
        let docRef = await addDoc(collection(db, "Canciones"), cancion);
        cancion.Id = docRef.id;
      } catch (error) {
        console.error(error);
      }
    }
    dispatch({
      type: GUARDA_CANCION,
      payload: cancion,
    });
  };

  const setCancionTieneAudio = async (idCancion, tieneAudio) => {
    const db = getFirestore();
    const presentacionRef = doc(db, "Canciones", idCancion);
    await updateDoc(presentacionRef, {
      TieneAudio: tieneAudio,
    });

    dispatch({
      type: SET_CANCION_TIENEAUDIO,
      payload: { idCancion, tieneAudio },
    });
  };

  const setCancionAgregando = (agregando) => {
    dispatch({
      type: SET_CANCION_AGREGANDO,
      payload: agregando,
    });
  };

  const GetAcordes = async () => {
    mostrarLoadingFc(true);
    let acordes = [];
    let accList = localStorage.getItem("acordes");
    let accV = localStorage.getItem("accV");

    if (accList && accV === process.env.REACT_APP_ACORDE_VERSION) {
      acordes = JSON.parse(accList);
    } else {
      const db = getFirestore();

      const querySnapshot = await getDocs(collection(db, "Acordes"));

      querySnapshot.forEach((doc) => {
        let objAcorde = doc.data();
        objAcorde.Id = doc.id;
        acordes.push(objAcorde);
      });
      localStorage.setItem("acordes", JSON.stringify(acordes));
      localStorage.setItem("accV", process.env.REACT_APP_ACORDE_VERSION);
    }
    dispatch({
      type: SET_ACORDES_TODOS,
      payload: acordes,
    });
  };

  const setCancionAEditar = (IdCancion) => {
    dispatch({
      type: SET_CANCION_EDIT,
      payload: IdCancion,
    });
  };

  const setUserLocation = (location) => {
    dispatch({
      type: SET_USER_LOCATION,
      payload: location,
    });
  };

  ///UTILES
  const cargaAcordes = async () => {
    const db = getFirestore();
    let listado = [];
    listado =
      JSON.parse(`[{"Id":"DoM","Orden":1,"Tipo":"May","Variaciones":"DOM-doM -DO - Do -DoM","OrdEval":156},
      {"Id":"Do#M","Orden":2,"Tipo":"May","Variaciones":"DO#M-do#M-DO# -Do# ","OrdEval":155},
      {"Id":"ReM","Orden":3,"Tipo":"May","Variaciones":"REM-reM-RE - Re -ReM","OrdEval":154},
      {"Id":"Re#M","Orden":4,"Tipo":"May","Variaciones":"RE#M-re#M-RE# -Re#M","OrdEval":153},
      {"Id":"MiM","Orden":5,"Tipo":"May","Variaciones":"MIM-miM- MI -MiM","OrdEval":152},
      {"Id":"FaM","Orden":6,"Tipo":"May","Variaciones":"FAM-faM-FA -FaM","OrdEval":151},
      {"Id":"Fa#M","Orden":7,"Tipo":"May","Variaciones":"FA#M-fa#M-FA# -Fa#M","OrdEval":150},
      {"Id":"SolM","Orden":8,"Tipo":"May","Variaciones":"SOLM-SOlM-solM- SOL -SolM","OrdEval":149},
      {"Id":"Sol#M","Orden":9,"Tipo":"May","Variaciones":"SOL#M-Sol#M-sol#M-SOL# ","OrdEval":148},
      {"Id":"LaM","Orden":10,"Tipo":"May","Variaciones":"LAM-laM- LA -LaM","OrdEval":147},
      {"Id":"La#M","Orden":11,"Tipo":"May","Variaciones":"LA#M-la#M-LA# -La#M","OrdEval":146},
      {"Id":"SiM","Orden":12,"Tipo":"May","Variaciones":"SIM-siM- SI -SiM","OrdEval":145},
      {"Id":"dom","Orden":1,"Tipo":"men","Variaciones":"Dom -DOm - do ","OrdEval":144},
      {"Id":"do#m","Orden":2,"Tipo":"men","Variaciones":"Do#m-DO#m-do# ","OrdEval":143},
      {"Id":"rem","Orden":3,"Tipo":"men","Variaciones":"Rem-REm- re ","OrdEval":142},
      {"Id":"re#m","Orden":4,"Tipo":"men","Variaciones":"Re#m-RE#m-re# ","OrdEval":141},
      {"Id":"mim","Orden":5,"Tipo":"men","Variaciones":"Mim-MIm-mim","OrdEval":140},
      {"Id":"fam","Orden":6,"Tipo":"men","Variaciones":"Fam-FAm- fa ","OrdEval":139},
      {"Id":"fa#m","Orden":7,"Tipo":"men","Variaciones":"Fa#m-FA#m-fa# ","OrdEval":138},
      {"Id":"solm","Orden":8,"Tipo":"men","Variaciones":"Solm-SOLm","OrdEval":137},
      {"Id":"sol#m","Orden":9,"Tipo":"men","Variaciones":"Sol#m-SOL#m-sol# ","OrdEval":136},
      {"Id":"lam","Orden":10,"Tipo":"men","Variaciones":"Lam -lam -LAm ","OrdEval":135},
      {"Id":"la#m","Orden":11,"Tipo":"men","Variaciones":"La#m-LA#m-la# ","OrdEval":134},
      {"Id":"sim","Orden":12,"Tipo":"men","Variaciones":"SIm- Sim - sim ","OrdEval":133},
      {"Id":"Do7","Orden":1,"Tipo":"May7","Variaciones":"DOM7-do7-DO7","OrdEval":132},
      {"Id":"Do#7","Orden":2,"Tipo":"May7","Variaciones":"DO#M7-do#7-DO#7","OrdEval":131},
      {"Id":"Re7","Orden":3,"Tipo":"May7","Variaciones":"REM7-reM7-RE7","OrdEval":130},
      {"Id":"Re#7","Orden":4,"Tipo":"May7","Variaciones":"RE#M7-re#M7-RE#7","OrdEval":129},
      {"Id":"Mi7","Orden":5,"Tipo":"May7","Variaciones":"MIM7-miM7-MI7","OrdEval":128},
      {"Id":"Fa7","Orden":6,"Tipo":"May7","Variaciones":"FAM7-faM7-FA7","OrdEval":127},
      {"Id":"Fa#7","Orden":7,"Tipo":"May7","Variaciones":"FA#M7-fa#M7-FA#7","OrdEval":126},
      {"Id":"Sol7","Orden":8,"Tipo":"May7","Variaciones":"SOLM7-SOlM7-solM7-SOL7","OrdEval":125},
      {"Id":"Sol#7","Orden":9,"Tipo":"May7","Variaciones":"SOL#M7-Sol#M7-sol#M7-SOL#7","OrdEval":124},
      {"Id":"La7","Orden":10,"Tipo":"May7","Variaciones":"LAM7-laM7-LA7-LaM7","OrdEval":123},
      {"Id":"La#7","Orden":11,"Tipo":"May7","Variaciones":"LA#M7-la#M7-LA#7-La#7","OrdEval":122},
      {"Id":"Si7","Orden":12,"Tipo":"May7","Variaciones":"SIM7-siM7-SI7-SiM7","OrdEval":121},
      {"Id":"Dom7","Orden":1,"Tipo":"men7","Variaciones":"DOm7-dom7-Dom7-DOM7","OrdEval":120},
      {"Id":"Dom#7","Orden":2,"Tipo":"men7","Variaciones":"DOm#7-dom#7-DOm#7-Dom#7","OrdEval":119},
      {"Id":"Rem7","Orden":3,"Tipo":"men7","Variaciones":"REm7-rem7-REm7","OrdEval":118},
      {"Id":"Rem#7","Orden":4,"Tipo":"men7","Variaciones":"RE#m7-re#m7-REm#7-Re#m7","OrdEval":117},
      {"Id":"Mim7","Orden":5,"Tipo":"men7","Variaciones":"MIm7-mim7-Mim7","OrdEval":116},
      {"Id":"Fam7","Orden":6,"Tipo":"men7","Variaciones":"FAm7-fam7-Fam7","OrdEval":115},
      {"Id":"Fam#7","Orden":7,"Tipo":"men7","Variaciones":"FA#m7-fa#m7-Fam#7","OrdEval":114},
      {"Id":"Solm7","Orden":8,"Tipo":"men7","Variaciones":"SOLm7-SOlm7-solm7-SoLm7-Solm7","OrdEval":113},
      {"Id":"Solm#7","Orden":9,"Tipo":"men7","Variaciones":"SOL#m7-Sol#m7-sol#m7-SOl#m7","OrdEval":112},
      {"Id":"Lam7","Orden":10,"Tipo":"men7","Variaciones":"LAm7-lam7-Lam7","OrdEval":111},
      {"Id":"Lam#7","Orden":11,"Tipo":"men7","Variaciones":"LA#m7-la#m7-La#m7","OrdEval":110},
      {"Id":"Sim7 ","Orden":12,"Tipo":"men7","Variaciones":"SIm7-sim7-Sim7","OrdEval":109},
      {"Id":"Dodis","Orden":1,"Tipo":"dis","Variaciones":"Dodis-Dodim-DOdis-Dodim","OrdEval":108},
      {"Id":"Do#dis","Orden":2,"Tipo":"dis","Variaciones":"Do#dis-Do#dim-DO#dis-Do#dim","OrdEval":107},
      {"Id":"Redis","Orden":3,"Tipo":"dis","Variaciones":"Redis-Redim-REdis-Redim","OrdEval":106},
      {"Id":"Re#dis","Orden":4,"Tipo":"dis","Variaciones":"Re#dis-Re#dim-RE#dis-Re#dim","OrdEval":105},
      {"Id":"Midis","Orden":5,"Tipo":"dis","Variaciones":"Midis-Midim-MIdis-Midim","OrdEval":104},
      {"Id":"Fadis","Orden":6,"Tipo":"dis","Variaciones":"Fadis-Fadim-FAdis-Fadim","OrdEval":103},
      {"Id":"Fa#dis","Orden":7,"Tipo":"dis","Variaciones":"Fa#dis-Fa#dim-FA#dis-Fa#dim","OrdEval":102},
      {"Id":"Soldis","Orden":8,"Tipo":"dis","Variaciones":"Soldis-Soldim-SOLdis-Soldim","OrdEval":101},
      {"Id":"Sol#dis","Orden":9,"Tipo":"dis","Variaciones":"Sol#dis-Sol#dim-SOL#dis-Sol#dim","OrdEval":100},
      {"Id":"Ladis","Orden":10,"Tipo":"dis","Variaciones":"Ladis-Ladim-LAdis-Ladim","OrdEval":99},
      {"Id":"La#dis","Orden":11,"Tipo":"dis","Variaciones":"La#dis-La#dim-LA#dis-La#dim","OrdEval":98},
      {"Id":"Sidis","Orden":12,"Tipo":"dis","Variaciones":"Sidis-Sidim-SIdis-Sidim","OrdEval":97},
      {"Id":"Domaj7","Orden":1,"Tipo":"maj7","Variaciones":"DoMaj7-Domaj7-DOMaj7-Domaj7","OrdEval":96},
      {"Id":"Do#maj7","Orden":2,"Tipo":"maj7","Variaciones":"Do#Maj7-Do#maj7-DO#Maj7-Do#maj7","OrdEval":95},
      {"Id":"Remaj7","Orden":3,"Tipo":"maj7","Variaciones":"ReMaj7-Remaj7-REMaj7-Remaj7","OrdEval":94},
      {"Id":"Re#maj7","Orden":4,"Tipo":"maj7","Variaciones":"Re#Maj7-Re#maj7-RE#Maj7-Re#maj7","OrdEval":93},
      {"Id":"Mimaj7","Orden":5,"Tipo":"maj7","Variaciones":"MiMaj7-Mimaj7-MIMaj7-Mimaj7","OrdEval":92},
      {"Id":"Famaj7","Orden":6,"Tipo":"maj7","Variaciones":"FaMaj7-Famaj7-FAMaj7-Famaj7","OrdEval":91},
      {"Id":"Fa#maj7","Orden":7,"Tipo":"maj7","Variaciones":"Fa#Maj7-Fa#maj7-FA#Maj7-Fa#maj7","OrdEval":90},
      {"Id":"Solmaj7","Orden":8,"Tipo":"maj7","Variaciones":"SolMaj7-Solmaj7-SOLMaj7-Solmaj7","OrdEval":89},
      {"Id":"Sol#maj7","Orden":9,"Tipo":"maj7","Variaciones":"Sol#Maj7-Sol#maj7-SOL#Maj7-Sol#maj7","OrdEval":88},
      {"Id":"Lamaj7","Orden":10,"Tipo":"maj7","Variaciones":"LaMaj7-Lamaj7-LAMaj7-Lamaj7","OrdEval":87},
      {"Id":"La#maj7","Orden":11,"Tipo":"maj7","Variaciones":"La#Maj7-La#maj7-LA#Maj7-La#maj7","OrdEval":86},
      {"Id":"Simaj7","Orden":12,"Tipo":"maj7","Variaciones":"SiMaj7-Simaj7-SIMaj7-Simaj7","OrdEval":85},
      {"Id":"Dosus","Orden":1,"Tipo":"sus","Variaciones":"Dosus-DoSus-DOsus-Dosus","OrdEval":84},
      {"Id":"Do#sus","Orden":2,"Tipo":"sus","Variaciones":"Do#sus-Do#Sus-DO#sus-Do#sus","OrdEval":83},
      {"Id":"Resus","Orden":3,"Tipo":"sus","Variaciones":"Resus-ReSus-REsus-Resus","OrdEval":82},
      {"Id":"Re#sus","Orden":4,"Tipo":"sus","Variaciones":"Re#sus-Re#Sus-RE#sus-Re#sus","OrdEval":81},
      {"Id":"Misus","Orden":5,"Tipo":"sus","Variaciones":"Misus-MiSus-MIsus-Misus","OrdEval":80},
      {"Id":"Fasus","Orden":6,"Tipo":"sus","Variaciones":"Fasus-FaSus-FAsus-Fasus","OrdEval":79},
      {"Id":"Fa#sus","Orden":7,"Tipo":"sus","Variaciones":"Fa#sus-Fa#Sus-FA#sus-Fa#sus","OrdEval":78},
      {"Id":"Solsus","Orden":8,"Tipo":"sus","Variaciones":"Solsus-SolSus-SOLsus-Solsus","OrdEval":77},
      {"Id":"Sol#sus","Orden":9,"Tipo":"sus","Variaciones":"Sol#sus-Sol#Sus-SOL#sus-Sol#sus","OrdEval":76},
      {"Id":"Lasus","Orden":10,"Tipo":"sus","Variaciones":"Lasus-LaSus-LAsus-Lasus","OrdEval":75},
      {"Id":"La#sus","Orden":11,"Tipo":"sus","Variaciones":"La#sus-La#Sus-LA#sus-La#sus","OrdEval":74},
      {"Id":"Sisus","Orden":12,"Tipo":"sus","Variaciones":"Sisus-SiSus-Sisus","OrdEval":73},
      {"Id":"Do+","Orden":1,"Tipo":"+","Variaciones":"DO+-DO +-Do +","OrdEval":72},
      {"Id":"Do#+","Orden":2,"Tipo":"+","Variaciones":"DO#+-DO# +-Do# +","OrdEval":71},
      {"Id":"Re+","Orden":3,"Tipo":"+","Variaciones":"RE+-RE +-Re +","OrdEval":70},
      {"Id":"Re#+","Orden":4,"Tipo":"+","Variaciones":"RE#+-RE# +-Re# +","OrdEval":69},
      {"Id":"Mi+","Orden":5,"Tipo":"+","Variaciones":"MI+-MI +-Mi +","OrdEval":68},
      {"Id":"Fa+","Orden":6,"Tipo":"+","Variaciones":"FA+-FA +-Fa +","OrdEval":67},
      {"Id":"Fa#+","Orden":7,"Tipo":"+","Variaciones":"FA#+-FA# +-Fa# +","OrdEval":66},
      {"Id":"Sol+","Orden":8,"Tipo":"+","Variaciones":"SOL+-SOL +-Sol +","OrdEval":65},
      {"Id":"Sol#+","Orden":9,"Tipo":"+","Variaciones":"SOL#+-SOL# +-Sol# +","OrdEval":64},
      {"Id":"La+","Orden":10,"Tipo":"+","Variaciones":"LA+-LA +-La +","OrdEval":63},
      {"Id":"La#+","Orden":11,"Tipo":"+","Variaciones":"LA#+-LA# +-La# +","OrdEval":62},
      {"Id":"Si+","Orden":12,"Tipo":"+","Variaciones":"SI+-SI# +-Si# +","OrdEval":61},
      {"Id":"Do6","Orden":1,"Tipo":"May6","Variaciones":"DO6-do6-DO 6- Do 6","OrdEval":60},
      {"Id":"Do#6","Orden":2,"Tipo":"May6","Variaciones":"DO#6-do#6-DO# 6-Do# 6","OrdEval":59},
      {"Id":"Re6","Orden":3,"Tipo":"May6","Variaciones":"RE6-re6-RE 6- Re 6","OrdEval":58},
      {"Id":"Re#6","Orden":4,"Tipo":"May6","Variaciones":"RE#6-re#6-RE# 6-Re#6-Re# 6","OrdEval":57},
      {"Id":"Mi6","Orden":5,"Tipo":"May6","Variaciones":"MI6-Mi6-Mi 6-Mi 6","OrdEval":56},
      {"Id":"Fa6","Orden":6,"Tipo":"May6","Variaciones":"FA6-FA 6-Fa6-Fa 6","OrdEval":55},
      {"Id":"Fa#6","Orden":7,"Tipo":"May6","Variaciones":"FA#6-Fa#6-FA# 6-Fa# 6","OrdEval":54},
      {"Id":"Sol6","Orden":8,"Tipo":"May6","Variaciones":"SOL6-SOl6-Sol6- SOL 6 -Sol 6","OrdEval":53},
      {"Id":"Sol#6","Orden":9,"Tipo":"May6","Variaciones":"SOL#6-Sol#6-SoL#6-SOL# 6-Sol# 6","OrdEval":52},
      {"Id":"La6","Orden":10,"Tipo":"May6","Variaciones":"LA6-La6-LA 6-La 6","OrdEval":51},
      {"Id":"La#6","Orden":11,"Tipo":"May6","Variaciones":"LA#6-la#6-LA# 6-La# 6","OrdEval":50},
      {"Id":"Si6","Orden":12,"Tipo":"May6","Variaciones":"SI6-Si6-SI 6 -Si 6","OrdEval":49},
      {"Id":"dom6","Orden":1,"Tipo":"men6","Variaciones":"Dom6-DOm6-do6 ","OrdEval":48},
      {"Id":"do#m6","Orden":2,"Tipo":"men6","Variaciones":"Do#m6-DO#m6-do#6","OrdEval":47},
      {"Id":"rem6","Orden":3,"Tipo":"men6","Variaciones":"Rem6-REm6-re6","OrdEval":46},
      {"Id":"re#m6","Orden":4,"Tipo":"men6","Variaciones":"Re#m6-RE#m6-re#6","OrdEval":45},
      {"Id":"mim6","Orden":5,"Tipo":"men6","Variaciones":"Mim6-MIm6-mim6","OrdEval":44},
      {"Id":"fam6","Orden":6,"Tipo":"men6","Variaciones":"Fam6-FAm6-fa6","OrdEval":43},
      {"Id":"fa#m6","Orden":7,"Tipo":"men6","Variaciones":"Fa#m6-FA#m6-fa#6","OrdEval":42},
      {"Id":"solm6","Orden":8,"Tipo":"men6","Variaciones":"Solm6-SOLm6-Solm 6-solm 6","OrdEval":41},
      {"Id":"sol#m6","Orden":9,"Tipo":"men6","Variaciones":"Sol#m6-SOL#m6-sol#6","OrdEval":40},
      {"Id":"lam6","Orden":10,"Tipo":"men6","Variaciones":"Lam6-LAm6-lam 6","OrdEval":39},
      {"Id":"la#m6","Orden":11,"Tipo":"men6","Variaciones":"La#m6-LA#m6-la#6","OrdEval":38},
      {"Id":"sim6","Orden":12,"Tipo":"men6","Variaciones":"Sim6-SIm6-sim 6","OrdEval":37},
      {"Id":"Do7/6","Orden":1,"Tipo":"7/6","Variaciones":"DO6-do6-DO 6- Do 6","OrdEval":36},
      {"Id":"Do#7/6","Orden":2,"Tipo":"7/6","Variaciones":"DO#6-do#6-DO# 6-Do# 6","OrdEval":35},
      {"Id":"Re7/6","Orden":3,"Tipo":"7/6","Variaciones":"RE6-re6-RE 6- Re 6","OrdEval":34},
      {"Id":"Re#7/6","Orden":4,"Tipo":"7/6","Variaciones":"RE#6-re#6-RE# 6-Re#6-Re# 6","OrdEval":33},
      {"Id":"Mi7/6","Orden":5,"Tipo":"7/6","Variaciones":"MI6-Mi6-Mi 6-Mi 6","OrdEval":32},
      {"Id":"Fa7/6","Orden":6,"Tipo":"7/6","Variaciones":"FA6-FA 6-Fa6-Fa 6","OrdEval":31},
      {"Id":"Fa#7/6","Orden":7,"Tipo":"7/6","Variaciones":"FA#6-Fa#6-FA# 6-Fa# 6","OrdEval":30},
      {"Id":"Sol7/6","Orden":8,"Tipo":"7/6","Variaciones":"SOL6-SOl6-Sol6- SOL 6 -Sol 6","OrdEval":29},
      {"Id":"Sol#7/6","Orden":9,"Tipo":"7/6","Variaciones":"SOL#6-Sol#6-SoL#6-SOL# 6-Sol# 6","OrdEval":28},
      {"Id":"La7/6","Orden":10,"Tipo":"7/6","Variaciones":"LA6-La6-LA 6-La 6","OrdEval":27},
      {"Id":"La#7/6","Orden":11,"Tipo":"7/6","Variaciones":"LA#6-la#6-LA# 6-La# 6","OrdEval":26},
      {"Id":"Si7/6","Orden":12,"Tipo":"7/6","Variaciones":"SI6-Si6-SI 6 -Si 6","OrdEval":25},
      {"Id":"Do9","Orden":1,"Tipo":"May9","Variaciones":"DO9-Do9-DO 9-Do 9","OrdEval":24},
      {"Id":"Do#9","Orden":2,"Tipo":"May9","Variaciones":"DO#9-Do#9-DO# 9-Do# 9","OrdEval":23},
      {"Id":"Re9","Orden":3,"Tipo":"May9","Variaciones":"RE9-Re9-RE 9- Re 9","OrdEval":22},
      {"Id":"Re#9","Orden":4,"Tipo":"May9","Variaciones":"RE#9-Re#9-RE# 9-Re# 9-Re#9","OrdEval":21},
      {"Id":"Mi9","Orden":5,"Tipo":"May9","Variaciones":"MI9-Mi9-MI 9-Mi 9","OrdEval":20},
      {"Id":"Fa9","Orden":6,"Tipo":"May9","Variaciones":"FA9-Fa9-FA 9-Fa 9","OrdEval":19},
      {"Id":"Fa#9","Orden":7,"Tipo":"May9","Variaciones":"FA#9-Fa#9-FA# 9-Fa# 9","OrdEval":18},
      {"Id":"Sol9","Orden":8,"Tipo":"May9","Variaciones":"SOL9-SOl9-Sol9- SOL 9-Sol 9","OrdEval":17},
      {"Id":"Sol#9","Orden":9,"Tipo":"May9","Variaciones":"SOL#9-Sol#9-Sol# 9-SOL# 9","OrdEval":16},
      {"Id":"La9","Orden":10,"Tipo":"May9","Variaciones":"LA9-la9-LA 9-La 9","OrdEval":15},
      {"Id":"La#9","Orden":11,"Tipo":"May9","Variaciones":"LA#9-la#9-LA# 9-La# 9","OrdEval":14},
      {"Id":"Si9","Orden":12,"Tipo":"May9","Variaciones":"SI9-Si9-SI 9-Si 9","OrdEval":13},
      {"Id":"dom9","Orden":1,"Tipo":"men9","Variaciones":"Dom9-DOm9-do 9","OrdEval":12},
      {"Id":"do#m9","Orden":2,"Tipo":"men9","Variaciones":"Do#m9-DO#m9-do# 9","OrdEval":11},
      {"Id":"rem9","Orden":3,"Tipo":"men9","Variaciones":"Rem9-REm9-re9","OrdEval":10},
      {"Id":"re#m9","Orden":4,"Tipo":"men9","Variaciones":"Re#m9-RE#m9-re#9","OrdEval":9},
      {"Id":"mim9","Orden":5,"Tipo":"men9","Variaciones":"Mim9-MIm9-mim9-mi9","OrdEval":8},
      {"Id":"fam9","Orden":6,"Tipo":"men9","Variaciones":"Fam9-FAm9-fa9","OrdEval":7},
      {"Id":"fa#m9","Orden":7,"Tipo":"men9","Variaciones":"Fa#m9-FA#m9-fa#9","OrdEval":6},
      {"Id":"solm9","Orden":8,"Tipo":"men9","Variaciones":"Solm9-SOLm9-sol9","OrdEval":5},
      {"Id":"sol#m9","Orden":9,"Tipo":"men9","Variaciones":"Sol#m9-SOL#m9-sol#9","OrdEval":4},
      {"Id":"lam9","Orden":10,"Tipo":"men9","Variaciones":"Lam9-LAm9-la9-lam9","OrdEval":3},
      {"Id":"la#m9","Orden":11,"Tipo":"men9","Variaciones":"La#m9-LA#m9-la#9","OrdEval":2},
      {"Id":"sim9","Orden":12,"Tipo":"men9","Variaciones":"Sim9-SIm9-sim9","OrdEval":1}]`);
    try {
      listado.forEach(async (a) => {
        let id = a.Id;
        delete a["Id"];
        let ref = await setDoc(doc(db, "Acordes", id), a);
        console.log("ACORDE:", ref);
      });
    } catch (error) {
      console.error(error);
    }
  };
  // const updateCancionTieneAudio = async () => {

  //   const db = getFirestore();
  //   const q = query(collection(db, "Canciones"), where("Grupo", "!=", ""));
  //   const querySnapshot = await getDocs(q);
  //   let canciones = [];

  //   querySnapshot.forEach((doc) => {
  //     // doc.data() is never undefined for query doc snapshots
  //     let objCancion = doc.data();
  //     objCancion.FechaActualizacion = objCancion.FechaActualizacion.toDate();
  //     objCancion.FechaCreacion = objCancion.FechaCreacion.toDate();
  //     objCancion.Id = doc.id;
  //     canciones.push(objCancion);
  //   });

  //   canciones.forEach( async (c) => {

  //   const storage = getStorage();
  //   //const listRef = ref(storage, "Audios/39uxQtbHv2atG2ShBQdA");
  //   const listRef = ref(storage, `Audios/${c.Id}`);
  //   listAll (listRef)
  //     .then((res) => {
  //       res.items.forEach( async (itemRef) => {
  //         const cancionRef =  doc(db, "Canciones", c.Id);
  //         await updateDoc(cancionRef, {TieneAudio: true});
  //       });
  //     })
  //     .catch((error) => {console.log(error)});

  //   });
  // };

  const setReproduciendo = async (reproducir) => {
    dispatch({
      type: SET_REPRODUCIR,
      payload: reproducir,
    });
  };

  const setCancionCelebracionVisible = async (cancionVisible) => {
    dispatch({
      type: SET_CANCIONCELEBRACIONVISIBLE,
      payload: cancionVisible,
    });
  };

  const setSeModificaCelebracion = async (seModifica) => {
    dispatch({
      type: MODIFICA_CELEBRACION,
      payload: seModifica,
    });
  };

  const setCancionDeCelebracion = async (cancion) => {
    //mostrarLoadingFc(true);
    if (!cancion) return;

    //BUSCAR Celebración
    let listado = state.listadoCelebraciones.find(
      (l) => l.Id === cancion.IdCelebracion
    );
    ///en caso que state.listadoCelebraciones este vacio nos referimos al listado activo
    if (!state.listadoCelebraciones.length) {
      listado = state.celebracionActiva;
    }

    let listadoCanciones = listado.ListCancionesObj.filter((c) => {
      return c.Id != cancion.Id;
    });
    listadoCanciones.push(cancion);

    let listOrdenada = listadoCanciones.sort(function ordenarEscala(a, b) {
      return a.Ord - b.Ord;
    });

    let listStringCancionesGuardado = [];

    listOrdenada.forEach((obj, inx) => {
      if (!listado.Canciones) listado.Canciones = [];
      listStringCancionesGuardado.push(JSON.stringify(obj));
    });

    listado.Canciones = [...listStringCancionesGuardado];
    listado.ListCancionesObj = [...listOrdenada];
    const db = getFirestore();
    const presentacionRef = doc(db, "Presentaciones", cancion.IdCelebracion);
    await updateDoc(presentacionRef, {
      Canciones: listado.Canciones,
    });
  };

  const recargarDesdeBancoCanciones = async (cancionLlega) => {
    mostrarLoadingFc(true);
    if (!cancionLlega) return;

    //BUSCAR Celebración
    let listado = state.listadoCelebraciones.find(
      (l) => l.Id === cancionLlega.IdCelebracion
    );
    ///en caso que state.listadoCelebraciones este vacio nos referimos al listado activo
    if (!state.listadoCelebraciones.length) {
      listado = state.celebracionActiva;
    }

    let listadoCanciones = listado.ListCancionesObj.filter((c) => {
      return c.Id !== cancionLlega.Id;
    });

    //Llamar la canción desde la nube
    const db = getFirestore();
    const q = query(
      collection(db, "Canciones"),
      where("Id", "==", cancionLlega.Id)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((cancionDoc) => {
      ///Actualizar campos desde la nube
      let docCancion = cancionDoc.data();
      cancionLlega.Nombre = docCancion.Nombre;
      cancionLlega.Letra = docCancion.Cancion;
      cancionLlega.AcordeBase = docCancion.AcordeBase;
    });

    listadoCanciones.push(cancionLlega);

    let listOrdenada = listadoCanciones.sort(function ordenarEscala(a, b) {
      return a.Ord - b.Ord;
    });

    let listStringCancionesGuardado = [];

    listOrdenada.forEach((obj, inx) => {
      if (!listado.Canciones) listado.Canciones = [];
      listStringCancionesGuardado.push(JSON.stringify(obj));
    });

    listado.Canciones = [...listStringCancionesGuardado];
    listado.ListCancionesObj = [...listOrdenada];

    const presentacionRef = doc(
      db,
      "Presentaciones",
      cancionLlega.IdCelebracion
    );
    await updateDoc(presentacionRef, {
      Canciones: listado.Canciones,
    });
    return cancionLlega;
  };

  const setUsuarioComunidad = async (comunidad) => {
    dispatch({
      type: SET_USUARIO_COMUNIDAD,
      payload: comunidad,
    });
  };

  const setComunidadByLocation = async () => {
    mostrarLoadingFc(true);
    const db = getFirestore();
    const q = query(collection(db, `Comunidades`));
    const qSnap = await getDocs(q);
    let listComunidades = [];
    qSnap.forEach((doc) => {
      listComunidades.push({
        id: doc.id,
        Coordenadas: doc.data().Coordenadas,
        Direccion: doc.data().Direccion,
        Nombre: doc.data().Nombre,
      });
    });
    dispatch({
      type: SET_COMUNIDADES_TODAS,
      payload: listComunidades,
    });

    try {
      let minDistance = Infinity;
      //  let closestLocation;
      let currentLocation = await getLocation();
      let comunidadSet;
      if (!listComunidades) return;

      listComunidades.forEach((comunidad) => {
        let location = comunidad.Coordenadas;
        const distance = calculateDistance(
          currentLocation.latitude,
          currentLocation.longitude,
          location.latitude,
          location.longitude
        );

        if (distance < minDistance) {
          //closestLocation = location;
          minDistance = distance;
          comunidadSet = comunidad;
        }
      });
      if (comunidadSet) {
        dispatch({
          type: SET_USUARIO_COMUNIDAD,
          payload: comunidadSet.id,
        });
      } else {
        dispatch({
          type: SET_USUARIO_COMUNIDAD,
          payload: "JEM",
        });
      }

      return comunidadSet.id;
    } catch (error) {
      console.log("error: ", error);
      mostrarLoadingFc(false);
      if (getAuth().currentUser) {
        dispatch({
          type: SET_USUARIO_COMUNIDAD,
          payload: `${getAuth().currentUser.photoURL}`,
        });
      } else {
        dispatch({
          type: SET_USUARIO_COMUNIDAD,
          payload: "JEM",
        });
      }
      return "JEM";
    }
  };

  const MigrarBD = async () => {
    const db = getFirestore();
    const queryPresentaciones = query(
      collection(db, `/PresentacionesOrg/JEM/Presentaciones`)
    );
    const qSnap01 = await getDocs(queryPresentaciones);
    let presentacionesActuales = [];
    qSnap01.forEach((doc) => {
      if (doc.data().Descripción) {
        presentacionesActuales.push({
          Id: doc.id,
          //Nombre: doc.data().Nombre,
          Canciones: doc.data().Canciones,
          Descripción: doc.data().Descripción,
          CreateDate: doc.data().CreateDate.toDate(),
          Activa: doc.data().Activa,
        });
      }
    });
    console.log("ESCUCHA PRESENTACIONES", presentacionesActuales);
    let presentacionesNew = [];
    presentacionesActuales.forEach((p) => {
      let date = Timestamp.fromDate(p.CreateDate);
      presentacionesNew.push({
        Nombre: p.Id,
        Canciones: p.Canciones,
        Descripción: p.Descripción,
        CreateDate: date,
        Activa: p.Activa,
        IdComunidad: "JEM",
      });
    });

    try {
      presentacionesNew.forEach((element) => {
        addDoc(collection(db, "Presentaciones"), element);
      });
    } catch (error) {
      console.log(error);
      return null;
    }

    //   if (presentaciones.length > 0) {
    //     presentaciones.forEach((p) => {
    //       let canObj = [];
    //       p.Canciones.forEach((c) => {
    //         let obj = JSON.parse(c);
    //         obj.IdCelebracion = p.Id;
    //         canObj.push(obj);
    //       });
    //       p.ListCancionesObj = [...canObj];
    //     });

    //     dispatch({
    //       type: SET_LISTADO_CELEBRACIONES,
    //       payload: presentaciones,
    //     });
    //   } else {
    //     dispatch({
    //       type: SET_LISTADO_CELEBRACIONES,
    //       payload: presentaciones,
    //     });
    //     mostrarLoadingFc(false);
    //   }

    // unsuscribePresentaciones = unsuscribe;
  };

  const UpdateCancionesDePresentaciones = async () => {
    if (unsuscribePresentaciones) {
      unsuscribePresentaciones();
    }
    let arrayUpdate = [];

    if (
      state.canciones &&
      state.canciones.length > 0 &&
      state.listadoCelebraciones &&
      state.listadoCelebraciones.length > 0
    ) {
      let arrayCanciones = [];
      state.listadoCelebraciones.forEach((p) => {
        p.Canciones.forEach((cancionPresString) => {
          let cancionPresentacion = JSON.parse(cancionPresString);
          let canGeneral = state.canciones.find(
            (ele) => ele.Id === cancionPresentacion.Id
          );
          if (
            canGeneral &&
            canGeneral.TieneAudio &&
            !cancionPresentacion.TieneAudio
          ) {
            ////==================================================
            cancionPresentacion.TieneAudio = true;
            cancionPresentacion.IdCelebracion = p.Id;
            // console.log("cancionPresentacion: ", cancionPresentacion);
            arrayCanciones.push(cancionPresentacion);
            ////==================================================
          }
        });
      });
      console.log("arrayCanciones: ", arrayCanciones);

      const groupByCategory = arrayCanciones.reduce((group, presentacion) => {
        const { IdCelebracion } = presentacion;
        group[IdCelebracion] = group[IdCelebracion] ?? [];
        group[IdCelebracion].push(presentacion);
        return group;
      }, {});

      Object.keys(groupByCategory).every((p) => {
        let presUpdate = state.listadoCelebraciones.find((l) => l.Id === p);
        console.log("groupByCategory: ", groupByCategory);
        console.log("presUpdate: ", presUpdate);

        if (presUpdate) {
          groupByCategory[p].forEach((c) => {
            presUpdate.ListCancionesObj = [
              ...presUpdate.ListCancionesObj.filter(
                (cancion) => cancion.Id !== c.Id
              ),
              c,
            ];
          });

          console.log(
            "presUpdate.ListCancionesObj: ",
            presUpdate.ListCancionesObj
          );
          let listOrdenada = presUpdate.ListCancionesObj.sort(
            function ordenarEscala(a, b) {
              return a.Ord - b.Ord;
            }
          );
          let listStringCancionesGuardado = [];
          listOrdenada.forEach((obj, inx) => {
            listStringCancionesGuardado.push(JSON.stringify(obj));
          });

          console.log(
            "presUpdate: ",
            presUpdate.ListCancionesObj[0].IdCelebracion
          );
          const db = getFirestore();
          const presentacionRef = doc(
            db,
            "Presentaciones",
            presUpdate.ListCancionesObj[0].IdCelebracion
          );

          updateDoc(presentacionRef, {
            Canciones: listStringCancionesGuardado,
          });
        }
        return false;
      });

      // arrayUpdate.push({
      //   listCan: [
      //     ...p.ListCancionesObj.filter((c) => c.Id !== cancionPresentacion.Id),
      //     cancionPresentacion,
      //   ],
      //   IdPres: p.Id,
      //   Nombre: p.Nombre,
      // });
      // console.log("arrayUpdate: ", arrayUpdate);

      // let listOrdenada = listadoCanciones.sort(function ordenarEscala(a, b) {
      //   return a.Ord - b.Ord;
      // });

      // let listStringCancionesGuardado = [];

      // listOrdenada.forEach((obj, inx) => {
      //   if (!listado.Canciones) listado.Canciones = [];
      //   listStringCancionesGuardado.push(JSON.stringify(obj));
      // });

      // listado.Canciones = [...listStringCancionesGuardado];
      // listado.ListCancionesObj = [...listOrdenada];
      // const db = getFirestore();
      // const presentacionRef = doc(db, "Presentaciones", cancion.IdCelebracion);
      // await updateDoc(presentacionRef, {
      //   Canciones: listado.Canciones,
      // });
    }
  };

  const setReproductorAbierto = (objRepAbierto) => {
    if (!objRepAbierto) return;
    dispatch({
      type: SET_REPRODUCTOR_ABIERTO,
      payload: objRepAbierto,
    });
  };

  return (
    <CelebracionesContext.Provider
      value={{
        celebracionActiva: state.celebracionActiva,
        listadoCelebraciones: state.listadoCelebraciones,
        existenCelebraciones: state.existenCelebraciones,
        mostrarLoading: state.mostrarLoading,
        grupoCanciones: state.grupoCanciones,
        canciones: state.canciones,
        cancionAgregadaError: state.cancionAgregadaError,
        cancionAgregando: state.cancionAgregando,
        acordes: state.acordes,
        idCancionAEditar: state.idCancionAEditar,
        actualizarListado: state.actualizarListado,
        reproduciendo: state.reproduciendo,
        cancionCelebracionVisible: state.cancionCelebracionVisible,
        seModificaCelebracion: state.seModificaCelebracion,
        Comunidad: state.Comunidad,
        celebracionActivaOtraComunidad: state.celebracionActivaOtraComunidad,
        usuarioComunidad: state.usuarioComunidad,
        comunidadesTodas: state.comunidadesTodas,
        ArrReproductores: state.ArrReproductores,
        userLocation: state.userLocation,
        crearListadoBd,
        getListadoCelebraciones,
        addCancionesListado,
        activarListadoById,
        llamarCelebracionActiva,
        ordenarEliminardeCelebracion,
        eliminarListadoById,
        setActualizarListado,
        getGruposBd,
        getcancionesTodas,
        guardaCancion,
        setCancionAgregando,
        GetAcordes,
        setCancionAEditar,
        mostrarLoadingFc,
        setReproduciendo,
        setCancionCelebracionVisible,
        setCancionDeCelebracion,
        agregarContenidoBd,
        setSeModificaCelebracion,
        SetComunidadActiva,
        setUsuarioComunidad,
        setComunidadByLocation,
        setEscuchaActiva,
        setReproductorAbierto,
        setUserLocation,
        recargarDesdeBancoCanciones,
        setCancionTieneAudio,
        //updateCancionTieneAudio
        // cargaAcordes,
        // MigrarBD,
        // UpdateCancionesDePresentaciones,
      }}
    >
      {props.children}
    </CelebracionesContext.Provider>
  );
};

export default CelebracionesState;
