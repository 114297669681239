import { initializeApp } from "firebase/app";
// import { getStorage } from "firebase/storage";
// import { getFirestore } from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  getAuth,
  updateProfile,
} from "firebase/auth";
//Config de cancionero.cl
const firebaseConfig = {
  apiKey: "AIzaSyB8utHXf9qtbbWa6VX0xIPP9M8PiALuKXI",
  authDomain: "song-book-62d9d.firebaseapp.com",
  projectId: "song-book-62d9d",
  storageBucket: "song-book-62d9d.appspot.com",
  messagingSenderId: "647399541236",
  appId: "1:647399541236:web:d183afa1b94f07085991a1",
  measurementId: "G-X6QZ81954L",
};

initializeApp(firebaseConfig);
//   const defaultStorage = getStorage();
//   const defaultFirestore = getFirestore();
const auth = getAuth();

export async function actualizaUsuario(usuario: any) {
  const user = auth.currentUser;
  if (user) {
    updateProfile(user, {
      displayName: usuario.displayName,
      photoURL: usuario.photoURL,
    })
      .then(() => {
        return true;
      })
      .catch((error) => {
        return false;
      });
  }
}

export async function CrearUsuario(userLogin: any) {
  createUserWithEmailAndPassword(auth, userLogin.email, userLogin.password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log({ errorCode, errorMessage });
    });
}
export async function loginUser(userLogin: any) {}
