import {
  ELIMINAR_LISTADO,
  GET_CELEBRACION_ACTIVA,
  SET_LISTADO_CELEBRACIONES,
  MOSTRAR_LOADING,
  ACTIVAR_LISTA,
  CREAR_LISTADO,
  GET_GRUPOS,
  GET_CANCIONES,
  ADD_CANCION_LISTADO,
  SET_CANCIONES_GRUPOS,
  GUARDA_CANCION,
  SET_CANCION_AGREGANDO,
  SET_ACORDES_TODOS,
  SET_CANCION_EDIT,
  ACTIVAR_ESCUCHA,
  ACTIVAR_ESCUCHA_PRESENTACIONES,
  SET_ACTUALIZAR_LISTADO,
  SET_REPRODUCIR,
  SET_CANCIONCELEBRACIONVISIBLE,
  UPDATE_CELEBRACION,
  MODIFICA_CELEBRACION,
  SET_COMUNIDAD,
  SET_USUARIO_COMUNIDAD,
  SET_COMUNIDADES_TODAS,
  SET_REPRODUCTOR_ABIERTO,
  SET_USER_LOCATION,
  SET_CANCION_TIENEAUDIO,
} from "../types";

const CelebracionesReducer = (state, action) => {
  //metodos auxiliares
  const setCancionesEnGrupos = (grupos, canciones) => {
    let grupoConCanciones = [];
    grupos.forEach((g) => {
      g.CancionesDelGrupo = [];
      g.CancionesDelGrupo = canciones
        .filter((c) => c.Grupo === g.NombreGrupo)
        .sort(
          (a, b) =>
            new Date(b.FechaActualizacion) - new Date(a.FechaActualizacion)
        );
      grupoConCanciones.push(g);
    });
    return grupoConCanciones;
  };

  const activarListado = (listado, idList) => {
    const retorno = [];
    listado.forEach((p) => {
      if (p.Id === idList) {
        p.Activa = true;
      } else {
        p.Activa = false;
      }
      retorno.push(p);
    });

    return retorno.sort((a, b) => (a.Activa < b.Activa ? 1 : -1));
  };

  switch (action.type) {
    case MOSTRAR_LOADING:
      return {
        ...state,
        mostrarLoading: action.payload,
      };
    case GET_CELEBRACION_ACTIVA:
      return {
        ...state,
        celebracionActiva: action.payload,
        mostrarLoading: false,
      };
    case SET_LISTADO_CELEBRACIONES:
      return {
        ...state,
        listadoCelebraciones: action.payload.sort((a, b) =>
          a.Activa < b.Activa ? 1 : -1
        ),
        mostrarLoading: false,
      };
    case ELIMINAR_LISTADO:
      return {
        ...state,
        listadoCelebraciones: state.listadoCelebraciones.filter(
          (p) => p.Id !== action.payload
        ),
        mostrarLoading: false,
      };
    case ACTIVAR_LISTA:
      return {
        ...state,
        listadoCelebraciones: activarListado(
          state.listadoCelebraciones,
          action.payload
        ),
        mostrarLoading: false,
        seModificaCelebracion: true,
      };
    case CREAR_LISTADO:
      return {
        ...state,
        listadoCelebraciones: [...state.listadoCelebraciones, action.payload],
        mostrarLoading: false,
      };
    case GET_GRUPOS:
      return {
        ...state,
        grupoCanciones: action.payload,
        mostrarLoading: false,
      };
    case GET_CANCIONES:
      return {
        ...state,
        canciones: action.payload,
        grupoCanciones: setCancionesEnGrupos(
          state.grupoCanciones ? state.grupoCanciones : [],
          action.payload
        ),
        mostrarLoading: false,
      };
    case ADD_CANCION_LISTADO:
      let presentacionFilter = state.listadoCelebraciones.filter(
        (p) => p.Id !== action.payload.Id
      );
      presentacionFilter.push(action.payload);
      return {
        ...state,
        listadoCelebraciones: presentacionFilter,
        mostrarLoading: false,
      };
    case SET_CANCIONES_GRUPOS:
      return {
        ...state,
        canciones: [...state.canciones, ...action.payload],
        grupoCanciones: setCancionesEnGrupos(
          state.grupoCanciones ? state.grupoCanciones : [],
          [...state.canciones, ...action.payload]
        ),
      };
    case SET_CANCION_AGREGANDO:
      return {
        ...state,
        cancionAgregando: action.payload,
      };
    case GUARDA_CANCION:
      let arrFilterId = state.canciones.filter(
        (d) => d.Id !== action.payload.Id
      );

      return {
        ...state,
        canciones: [action.payload, ...arrFilterId],
        grupoCanciones: setCancionesEnGrupos(
          state.grupoCanciones ? state.grupoCanciones : [],
          [action.payload, ...arrFilterId]
        ),
        mostrarLoading: false,
        cancionAgregando: true,
      };
    case SET_ACORDES_TODOS:
      return {
        ...state,
        acordes: action.payload,
        mostrarLoading: false,
      };
    case SET_CANCION_EDIT:
      return {
        ...state,
        idCancionAEditar: action.payload,
      };
    case ACTIVAR_ESCUCHA:
      return {
        ...state,
        seActivoEscucha: action.payload,
      };
    case ACTIVAR_ESCUCHA_PRESENTACIONES:
      return {
        ...state,
        seActivoEscuchaPresentaciones: true,
      };

    case SET_ACTUALIZAR_LISTADO:
      return {
        ...state,
        actualizarListado: action.payload,
      };

    case SET_REPRODUCIR:
      return {
        ...state,
        reproduciendo: action.payload,
      };

    case SET_CANCIONCELEBRACIONVISIBLE:
      return {
        ...state,
        cancionCelebracionVisible: action.payload,
      };

    case UPDATE_CELEBRACION:
      return {
        ...state,
        listadoCelebraciones: [
          ...state.listadoCelebraciones.filter(
            (c) => c.Id != action.payload.Id
          ),
          action.payload,
        ],
        cancionCelebracionVisible: action.payload,
        seActivoEscucha: true,
      };

    case MODIFICA_CELEBRACION:
      return {
        ...state,
        seModificaCelebracion: action.payload,
      };
    case SET_COMUNIDAD:
      return {
        ...state,
        usuarioComunidad: action.payload,
        Comunidad: action.payload,
      };
    case SET_USUARIO_COMUNIDAD:
      return {
        ...state,
        usuarioComunidad: action.payload,
        Comunidad: action.payload,
        mostrarLoading: false,
      };
    case SET_COMUNIDADES_TODAS:
      return {
        ...state,
        comunidadesTodas: action.payload,
      };
    case SET_REPRODUCTOR_ABIERTO:
      return {
        ...state,
        ArrReproductores: [
          ...state.ArrReproductores.filter((d) => {
            return d.idRep !== action.payload.idRep;
          }),
          action.payload,
        ],
      };
    case SET_USER_LOCATION:
      return {
        ...state,
        userLocation: action.payload,
        loadingFromUser: false,
      };
    case SET_CANCION_TIENEAUDIO:
      let cancion = state.canciones.find(
        (c) => c.Id === action.payload.idCancion
      );
      console.log("cancion: ", cancion);
      cancion.TieneAudio = action.payload.tieneAudio;

      return {
        ...state,
        canciones: [
          ...state.canciones.filter((f) => f.Id !== action.payload.idCancion),
          cancion,
        ],
      };
    default:
      return state;
  }
};
export default CelebracionesReducer;
