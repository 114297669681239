import {
  SET_USER_ACTIVE,
  SET_USER_ERROR,
  SET_LOADING_USER,
  SET_USER_CREADO,
  SET_PROFILE_USER,
} from "../types";

const UserReducer = (state, action) => {
  switch (action.type) {
    case SET_USER_ACTIVE:
      return {
        ...state,
        userActive: action.payload,
        Comunidad: action.payload ? action.payload.photoURL : "JEM",
        loadingFromUser: false,
      };
    case SET_USER_ERROR:
      return { ...state, userError: action.payload, loadingFromUser: false };
    case SET_LOADING_USER:
      return { ...state, loadingFromUser: action.payload };
    case SET_USER_CREADO:
      return {
        ...state,
        nuevoUsuarioCreado: action.payload,
        loadingFromUser: false,
      };
    case SET_PROFILE_USER:
      return {
        ...state,
        profileUser: action.payload,
        loadingFromUser: false,
      };
    default:
      return state;
  }
};
export default UserReducer;
